import { useState } from "react";
import "./growth-journey.css";
import YearlyOff from "../../assets/yearly-off.svg";
import YearlyOffOnTop from "../../assets/yearly-off-on-top.svg";
import React from "react";
import {
  growthJourneyItemsMonthly,
  growthJourneyItemsYearly,
} from "../../actions/Constants";
import GrowthJourneyItem from "../../components/growther-journey/GrowthJourneyItem";

const GrowthJourney = ({
  showTitle = true,
  showSaveMore = true,
  showSaveMoreIconOnTop=false,
  onHandlePlanDetailsChange,
  userDetailsPlanId,
  onClickHandler,
  isLoggedIn
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState("MONTHLY");

  const items =
    selectedPeriod === "YEARLY"
      ? growthJourneyItemsYearly
      : growthJourneyItemsMonthly;
  return (
    <div className="growth-journey-container">
      {showTitle && (
        <h2 className="growth-journey-title">
          Start Your Growth Journey
        </h2>
      )}
      <div className="yearly-off-on-top-container">
        {showSaveMoreIconOnTop && (
          <img className="yearly-off" src={YearlyOffOnTop} alt="arrow" />
        )}
      </div>
      <div className="button-container">
        <button
          className={`button-period ${
            selectedPeriod === "MONTHLY" ? " button-period-select" : ""
          }`}
          style={{zIndex:9}}
          onClick={() => {
            setSelectedPeriod("MONTHLY");
          }}
        >
          Monthly
        </button>
        {!showSaveMoreIconOnTop && (
          <img className="yearly-off" src={YearlyOff} alt="arrow" />
        )}
        {showSaveMore && <span className="save-more">Save 44%</span>}
        <button
          className={`button-period button-yearly${
            selectedPeriod === "YEARLY" ? " button-period-select" : ""
          }`}
          style={{ borderTopLeftRadius: "0px", borderBottomLeftRadius:'0px'}}
          onClick={() => {
            setSelectedPeriod("YEARLY");
          }}
        >
          Yearly
        </button>
      </div>
      {showSaveMoreIconOnTop && (
        <div className="save-more-description">
          <span>
            Choose a yearly pricing plan and get additional discounts! Save 44%!
          </span>
        </div>
      )}
      <div className="growth-journey-items-container">
        {items.map((growth, index) => {
          return (
            <GrowthJourneyItem
              onHandlePlanDetailsChange={onHandlePlanDetailsChange}
              growth={growth}
              isSelected={growth?.subscriptionId === userDetailsPlanId}
              onClickHandler={onClickHandler}
              isLoggedIn={isLoggedIn}
            />
          );
        })}
      </div>
    </div>
  );
};

export default GrowthJourney;
