import React, { useState } from "react";
import "./promo-codes.css";
import CouponVector from "../../../../assets/promo-code-vector.svg";
import { Modal } from "react-bootstrap";
import PromoCodeModal from "./promo-codes/PromoCodeModal";
const mockData = [
  {
    code: "PROMOGROWFREE",
    description: "3 month Free Growthaholic Subsc.",
    used: 2,
    total: 20,
  },
  {
    code: "NOVA50GROWOFF",
    description: "50% Off Growthaholic Subsc. | Valid for 1 year",
    used: 10,
    total: "Unlimited",
  },
];

const PromoCodes = () => {
  const [showModal,setShowModal] = useState(false)
  const toggleModal = () =>{
    setShowModal(!showModal)
  }
  return (
    <>
      <div className="promo-code-container">
        <div className="promo-code-child-container">
          <div className="promo-code-header">
            <h2>Promocodes</h2>
            <button onClick={toggleModal}>ADD NEW</button>
          </div>
          <div className="promo-code-active-expired">
            <label>Active (12)</label>
            <label>Expired (6)</label>
          </div>

          <div className="coupons-container">
            {mockData.map((coupon) => (
              <>
                <div className="coupon-item-container">
                  <div className="coupon-item">
                    <label>
                      {coupon.used} of {coupon.total} Used
                    </label>
                    <label>{coupon.code}</label>
                    <label>{coupon.description}</label>
                  </div>
                  <img src={CouponVector} />
                </div>
                <hr />
              </>
            ))}
          </div>
          <div className="coupon-view-container">
            <button className="coupon-view-all">VIEW ALL</button>
          </div>
        </div>
      </div>
      <Modal show={showModal} dialogClassName="modal-container">
        <Modal.Body>
          <PromoCodeModal toggleModal={toggleModal} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PromoCodes;
