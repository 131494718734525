import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import './tabview.css';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabView = (props) => {
  const {
    label1 = "Report", 
    children = {}, 
    tabs = [], 
    service_Type = "Class", 
    status = "active", 
    onTabClick,
    isHidden = false,
    currentValue = 0,
  } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabClick(newValue);
  };

  React.useEffect(() => {
    if (currentValue === 0) {
      setValue(currentValue);
    }
  }, [currentValue]);


  return (
    <Box sx={{ width: "100%" }} className="tabview">
      {!isHidden && (
        <Box sx={{ maxWidth: { lg: "100%", xs: 320, sm: 480 } }}>
          <Tabs
            value={currentValue ? currentValue : value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            style={{ color: "#4E705A" }}
            textColor="#4E705A"
            fontSize={100}
            TabIndicatorProps={{
              sx: {
                backgroundColor: "#4E705A",
              },
            }}
          >
            {tabs.map((item, i) => (
              <Tab label={item.label} value={i} />
            ))}
          </Tabs>
        </Box>
      )}
      <CustomTabPanel>{children(service_Type)}</CustomTabPanel>
    </Box>
  );
}

export default TabView;
