import React, { useState } from "react";
import CancelIcon from "../../../../../assets/brown_cancel.png";
import Input from "../../../../../components/input/Input";
import {
  FormControl,
  MenuItem,
  Select,
  setRef,
  Switch,
  TextField,
} from "@mui/material";
import "./promo-code-modal.css";
const options = [
  { value: "founding_catalyst_subsc", label: "FOUNDING CATALYST SUBSC." },
  { value: "growthaholic_subsc", label: "GROWTHAHOLIC SUBSC." },
  { value: "minded_club_event", label: "MINDED CLUB EVENT" },
];

const PromoCodeModal = ({toggleModal}) => {
  const [discountType, setDiscountType] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [reference, setReference] = useState("");
  const [discount, setDiscount] = useState("");
  const [applyTo, setApplyTo] = useState("");
  const [numberOfUses, setNumberOfUses] = useState(0);
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className="promo-code-modal-container">
      <div className="promo-code-modal-header">
        <h2>Create Promocode</h2>
        <div>
          <img src={CancelIcon} onClick={toggleModal} />
        </div>
      </div>
      <div className="promo-code-modal-type">
        <h4>Select type of Promocode</h4>
        <div className="promo-code-modal-discount-container">
          <div className="promo-code-modal-discount">
            <input
              id="discountEuro"
              type="radio"
              name="discount"
              onChange={(e) => setDiscountType(e.target.value)}
            />
            <label for="discountEuro">€ Discount</label>
          </div>
          <div className="promo-code-modal-discount">
            <input
              id="discountPercentage"
              type="radio"
              name="discount"
              onChange={(e) => setDiscountType(e.target.value)}
            />
            <label for="discountPercentage">% Discount</label>
          </div>
        </div>
      </div>
      <div className="promo-code-body-container">
        <Input
          name="promoCode"
          value={promoCode}
          label="Promocode Code (the code customers will use)"
          onInputChange={(e) => setPromoCode(e.target.value)}
        />
        <Input
          value={reference}
          name="reference"
          label="Promocode Name/Reference"
          onInputChange={(e) => setReference(e.target.value)}
        />
        <Input
          value={discount}
          name="discount"
          label="Discount"
          onInputChange={(e) => setDiscount(e.target.value)}
        />
      </div>
      <div className="promo-code-body-container">
        <div className="promo-code-select">
          <label for="apply-to">APPLY TO</label>
          <FormControl fullWidth variant="standard">
            <Select id="apply-to" displayEmpty value={applyTo}>
              <MenuItem disabled value="">
                Choose one
              </MenuItem>
              {options.map(({ value, label }) => {
                return (
                  <MenuItem value={value} onClick={() => setApplyTo(value)}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="promo-code-modal-switch">
          <span>Set an end date</span>
          <label class="toggle-switch">
            <input type="checkbox" />
            <span class="slider"></span>
          </label>
        </div>
        <div className="promo-code-modal-date">
          <label for="valid-date">VALID UNTIL</label>
          <Input
            type="date"
            id="valid-date"
            value={startDate.toISOString().split("T")[0]}
            onInputChange={(e) => {
              setStartDate(new Date(e.target.value));
            }}
          />
        </div>
        <div className="promo-code-modal-switch">
          <span>Limit uses</span>
          <label class="toggle-switch">
            <input type="checkbox" />
            <span class="slider"></span>
          </label>
        </div>
        <Input
          value={numberOfUses}
          name="numberOfUses"
          onInputChange={(e) => {
            const value = e.target.value;
            !isNaN(value) && value.trim() !== "" && setNumberOfUses(value);
          }}
          label="Number of uses this promocode can be used"
        />
      </div>

      <div className="promo-code-modal-button-container">
        <button className="outlined" onClick={toggleModal}>CANCEL</button>
        <button className="filled">ADD</button>
      </div>
    </div>
  );
};

export default PromoCodeModal;
