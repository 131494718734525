import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';

import {useDispatch} from 'react-redux';
import ArrowBack from '@mui/icons-material/ArrowBack';

import AddObjective from './AddObjective';
import CustomizedSnackbars from  '../../../components/CustomizedSnackbars';
import Confirmation from './Confirmation';

import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import UserDetails from './UserDetails';
import Loading from '../../../components/loading/Loading';
import GuidelineInfo from './GuidelineInfo';

import {InitialUserDetails, InitialUserErrors} from '../../../utils/DefaultInitialValues';

import {baseURL} from '../../../actions/Constants';
import {saveAdmin} from '../../../actions/admin.actions';
import brown_cancel from '../../../assets/brown_cancel.png';
import {getBoardingHeaderText, getBoardingCTAText, isShowBackBtn, covertToLower, isBigText, getEndEpochAfterGivenDate} from '../../../utils/util';
import CustomerSelectPlan from './CustomerSelectPlan';
import CustomerPaymentView from './CustomerPaymentView';


const CustomerOnBoarding = (props) => {
	const {onHandleClose, navigateStepper = 0, userinfo = InitialUserDetails, isUpgradingPlan = false} = props;
	const navigate = useNavigate();
  	const dispatch = useDispatch();
  	const saveAdminAction = saveAdmin;
	const paymentRef = useRef();

  	const [isLoading, setLoading] = useState(false);
  	const [isError, setError] = useState(false);

  	const [stepperView, setStepperView] = useState(1);
  	const [userDetails, setUserDetails] = useState(userinfo);
  	const [userErrors, setUserErrors] = useState(InitialUserErrors);

  	const [isGuidelineChecked, setGuidelineChecked] = useState(false);
  	const [isProfilePicUpdate, setProfilePicUpdate] = useState(false);
  	const [newProfilePic, setProfileImage] = useState("");
	const [overallPrice, setOverallPrice] = useState(0);
	const [billingAddress, setBillingAddress] = useState({});

  	let isEdit = false;

  	useEffect(() => {
	    if(navigateStepper !== 0) {
	      setStepperView(navigateStepper);
	    }
  	}, [navigateStepper]);

  	const handleBack = () => {
    	setStepperView(stepperView - 1);
  	}

	const onHandleChange = (e) => {
	    setUserDetails({...userDetails, [e.target.name] : e.target.value});
	}

	const onHandleProfileChange = (e) => {
    	setUserDetails({...userDetails, 
    		profileDescription : {...userDetails.profileDescription, [e.target.name] : e.target.value}
    	});
  	}

  	const onDropdownChange = (name, list = []) => {
	    let latestList = [];
	    
	    if(name === "languages" || name === "languageSpeak") {
	      latestList = list.map((item, index) => {
	        const obj = {};
	        obj.value = item.value;
	        obj.id = item.id;
	        return obj;
	      });
	    } else {
	      latestList = list.map((item, index) => {
	        const obj = {};
	        obj.name = item.name;
	        obj.id = item.id;
	        return obj;
	      });
	    }

	    setUserDetails({
	    	...userDetails, 
	    	profileDescription: {...userDetails.profileDescription, [name] : latestList}
	    });
  	}

  	const onHandleAllService = () => {
    	onHandleClose();
    	navigate("/events-classes");
  	}

  	const onHandleSkipSubmit = () => {
	    const isValid = validate();
	    if(isValid) {
	      updateUser(true);
	    }
  	}

	


  	const onHandleProfileImageChange = (e) => {
	    let file = e.target.files[0];
	    let reader = new FileReader();

	    reader.onload = function(event) {
	      //setFile(e.target.result);
	    };
	    reader.readAsDataURL(file);

	    const extention = file.name.split('.').pop();

	    if(extention !== "png" && extention !== "jpg" ) {
	      window.alert("File does not support. You must use .png or .jpg ");
	      return false;
	    }
	    if(file.size > 2097152) {
	      window.alert("Image size should not be more than 2 MB");
	      return false;
	    }

	    setProfilePicUpdate(true);
	    const newPic = file;
	    setProfileImage(newPic);
	}


	const initiate_minded_payment_minders_subscription = async (
		purchaseId = "",
		paymentId = ""
	  ) => {
		const URL = `${baseURL}/minded-services/initiate_minded_payment_catalyst_subscription`;
		setLoading(true);
	
		const totalPrice = subscriptionTotalPrice || 0;
		setOverallPrice(totalPrice);
	
		const obj = {
		  purchaseId: purchaseId,
		  paymentId: paymentId,
		  description: name || "AK",
		  value: 0,
		  currency: "EUR",
		  customer: {
			customerInfo: {
			  customerName: name,
			  customerEmail: email,
			  billingAddress: {
				street1: accountDetails?.street1,
				street2: "",
				city: accountDetails?.city,
				postcode: accountDetails?.postcode,
				country: accountDetails?.country,
			  },
			},
		  },
		};
		
		return await fetch(URL, {
		  method: "POST",
		  body: JSON.stringify(obj),
		  headers: {
			"Content-Type": "application/json",
		  },
		})
		  .then((response) => {
			const resp = response;
	
			setError(false);
			setLoading(false);
			const { status = "" } = resp;
	
			if (status === 200 || status === 201) {
			  return response.json().then((res) => {
				console.log(
				  "initiate_minded_payment_minders_subscription response",
				  res
				);
				sessionStorage.setItem("checkout", JSON.stringify(res));
				sessionStorage.setItem("purchaseId", purchaseId);
				sessionStorage.setItem("transactionID", res?.transactionID);
				sessionStorage.setItem(
				  "transactionSignature",
				  res?.transactionSignature
				);
				sessionStorage.setItem("formContext", res?.formContext);
				sessionStorage.setItem(
				  "customerData",
				  JSON.stringify(accountDetails)
				);
				sessionStorage.setItem("actualAmount", parseFloat(totalPrice));
				sessionStorage.setItem("isRecurring", true);

	
				if (
				  paymentDetails[0] &&
				  paymentDetails[0].subscriptionTrialDuration !== 0
				) {
				  setStepperView(stepperView + 2);
				} else {
				  setStepperView(stepperView + 1);
				}
			  });
			} else {
			  //Error
			  setError(true);
			}
		  })
		  .catch((error) => {
			console.log(
			  "error initiate_minded_payment_minders_subscription",
			  error
			);
			setLoading(false);
			setError(true);
		  });
	  };
	
	const insertOrUpdatePurchase = async (userInfo = {}) => {
	  const URL = `${baseURL}/minded-services/insertOrUpdatePurchase`;
	  setLoading(true);
  
	  const purchaseInfo = {};
	  purchaseInfo.userId = userInfo?.userId || "";
	  purchaseInfo.userName = name || "";
	  purchaseInfo.serviceId = "";
	  purchaseInfo.scheduleId = "";
	  purchaseInfo.purchaseDate = new Date().getTime();
	  purchaseInfo.purchaseExpiryDate = getEndEpochAfterGivenDate(subscriptionTrialDuration);
	  purchaseInfo.purchaseType = "SUBSCRIPTION";
  
	  purchaseInfo.purchaseDetails = [];
  
	  const obj = {};
	  obj.pricingType = "Subscription";
	  obj.subscriptionId = subscriptionId;
	  obj.subscriptionName = subscriptionName;
	  obj.subscriptionPaymentPlan = subscriptionPaymentPlan;
	  obj.subscriptionPrice = subscriptionPrice;
	  obj.subscriptionTotalPrice = subscriptionTotalPrice;
	  obj.subscriptionPriceCurrency = subscriptionPriceCurrency;
	  obj.subscriptionTrialDuration = subscriptionTrialDuration;
	  obj.subscriptionPaymentDuration = subscriptionPaymentDuration;
	  purchaseInfo.purchaseDetails.push(obj);
  
	  purchaseInfo.purchaseAdditionalDetails = obj;
	  purchaseInfo.purchaseAmount = subscriptionTotalPrice;
	  purchaseInfo.transactionCurrency = "EUR";
	  purchaseInfo.paymentId = "";
	  purchaseInfo.authStatus = "auth_pending";
	  purchaseInfo.purchaseStatus = "pending";
  
	  purchaseInfo.trail = [];
	  //purchaseInfo.purchaseType = "subscription";
	  dispatch(saveAdminAction(userInfo));
  
	  //console.log("catalyst Subscription Purchase json--->", purchaseInfo);
  
	  return await fetch(URL, {
		method: "POST",
		body: JSON.stringify(purchaseInfo),
		headers: {
		  "Content-Type": "application/json",
		},
	  })
		.then((response) => {
		  const resp = response;
  
		  const { status = "" } = resp;
  
		  if (status === 200 || status === 201) {
			return response.json().then((res) => {
			  setLoading(false);
			  initiate_minded_payment_minders_subscription(
				res?.purchaseId,
				res?.paymentId
			  );
			});
		  } else {
			//Error
			setLoading(false);
			setError(true);
		  }
		})
		.catch((error) => {
		  console.log("error insertOrUpdatePurchase", error);
		  setLoading(false);
		  setError(true);
		});
	};

  	const onHandleAddressChange = (e) => {
    	setUserDetails({...userDetails, address : {...userDetails.address, [e.target.name] : e.target.value}});
  	}

	  const onHandlePlanDetailsChange = (plan) => {
		setUserDetails({
		  ...userDetails,
		  catalystDetails: { ...userDetails.catalystDetails, planDetails: {...plan,
			subscriptionName : plan?.title,
			subscriptionPaymentPlan : "recurring",
			subscriptionPrice : plan.price,
			subscriptionTotalPrice :  plan.price,
			subscriptionPriceCurrency : "EUR",
			subscriptionTrialDuration : 0,
			subscriptionPaymentDuration :plan.yearly ? "Annually" : "Monthly",
		  } },
		});
	  };
  	const onObjectiveDropdownChange = (name, list = []) => {
    	setUserDetails({...userDetails, profileDescription: {...userDetails.profileDescription, [name] : list}});
  	}

  	const goNext = () => {
	    const isValid = validate();

	    if(stepperView === 7) {
	      onHandleClose();
	      navigate("/dashboard");
	    }

	    if(isValid) {
			stepperView === 4 ? updateUser() : setStepperView(stepperView + 1);
	    }
  	}

	  const validate = () => {
		let isFormValid = true;
	
		let isGuidelineInvalid = false;
		let isNameInvalid = false;
		let isPhoneInvalid = false;
		let isStreetInvalid = false;
		let isApartmentInvalid = false;
		let isStateInvalid = false;
		let isCityInvalid = false;
		let isPincodeInvalid = false;
		let isCountryInvalid = false;
		let isLanguageSpeakInvalid = false;
		let isInterestCategoriesInvalid = false;
		let isStugleCategoriesInvalid = false;
		let isTaxNumberInvalid = false;
		let isBigPublicName = false;
	
		if (stepperView === 1) {
		  if (!isGuidelineChecked) {
			isGuidelineInvalid = true;
			isFormValid = false;
		  }
		} else if (stepperView === 3) {
		  if(name === "" && name.trim() === "") {
			isNameInvalid = true;
			isFormValid = false;
		  }
		  if(phone === "") {
			isPhoneInvalid = true;
			isFormValid = false;
		  }
	
		  if(street === "") {
			isStreetInvalid = true;
			isFormValid = false;
		  }
	
		  if(city === "") {
			isCityInvalid = true;
			isFormValid = false;
		  }
	
		  if(apartment === "") {
			isApartmentInvalid = true;
			isFormValid = false;
		  }
	
		  if(pincode === "") {
			isPincodeInvalid = true;
			isFormValid = false;
		  }
	
		  if(country === "") {
			isCountryInvalid = true;
			isFormValid = false;
		  }
	
		  if(publicName !== "" && isBigText(publicName)) {
			isBigPublicName = true;
			isFormValid = false;
		  }
	
		  if(languageSpeak?.length === 0) {
			isLanguageSpeakInvalid = true;
			isFormValid = false;
		  }
		} else if (stepperView === 4) {
		  isFormValid = paymentRef.current.validatePaymentAddress();
		} else if (stepperView === 6) {
		  if (interestCategories?.length === 0) {
			isInterestCategoriesInvalid = true;
			isFormValid = false;
		  }
	
		  if (stuggleCategories?.length === 0) {
			isStugleCategoriesInvalid = true;
			isFormValid = false;
		  }
	
		  if (taxNumber === "") {
			isTaxNumberInvalid = true;
			isFormValid = false;
		  }
		}
	
		setUserErrors({
		  ...userErrors,
		  guidelineError: isGuidelineInvalid,
		  nameError: isNameInvalid,
		  phoneError: isPhoneInvalid,
		  countryError: isCountryInvalid,
		  streetError: isStreetInvalid,
		  apartmentError: isApartmentInvalid,
		  stateError: isStateInvalid,
		  cityError: isCityInvalid,
		  pincodeError: isPincodeInvalid,
		  publicNameError: isBigPublicName,
		  languageSpeakError: isLanguageSpeakInvalid,
		  interestCategoriesError: isInterestCategoriesInvalid,
		  stuggleCategoriesError: isStugleCategoriesInvalid,
		  taxNumberError: isTaxNumberInvalid,
		});
	
		return isFormValid;
	  };
  	const uploadProfileImage = async (data = userDetails, updatedProfile, isSubmit = false) => {
	    const URL = `${baseURL}/minded-user/uploadUserProfilePicture`;
	    setLoading(true);

	    var imageFormData = new FormData();

	    imageFormData.append('userId', data?.userId);
	    imageFormData.append("file", newProfilePic);

	    return await fetch(URL, {
	      method: 'POST', // *GET, POST, PUT, DELETE, etc.

	      body: imageFormData // body data type must match "Content-Type" header
	    })
	    .then(response => {
	      	const resp = response;
	      	const {status = ""} = resp;

	      	setLoading(false);

	      	if(status === 200) {
		        return response.json().then(res => { 
		          dispatch(saveAdminAction(res));
		          if(isSubmit) {
		            setStepperView(4);
		          } else {
					insertOrUpdatePurchase(res);
				  }
		        });
	      	} else {
	        	setError(true);
	      	}
	    })
	    .catch((error) => {
	      	console.log('error uploadProfileImage', error);
	      	setLoading(false);
	      	if(isSubmit) {
		        setStepperView(7);
          	} else {
	          	insertOrUpdatePurchase(data);
          	}
	    });
  	}

  	const updateUser = async (isSubmit = false) => {
	    userDetails.status = "active";
	    isEdit = (userDetails?.userId && userDetails?.userId !== "");
	    const URL = isEdit ?  `${baseURL}/minded-user/updateUserDetails` : `${baseURL}/minded-user/createUser`;
	    setLoading(true);
	    
	    let updatedProfile = "";

	    return await fetch(URL, {
	      method: 'POST',
	      body: JSON.stringify(userDetails),
	      headers: {
	        'Content-Type': 'application/json'
	      }
	    })
	    .then(response => {
	      const resp = response;
	      const {status = ""} = resp;

	      setLoading(false);

	      if(status === 200 || status === 201) {
	        return response.json().then(res => { 
	          setUserDetails((prevState) => ({
	            ...prevState,
	            res,
	            userId: res?.userId
	          }));

	          dispatch(saveAdminAction(res));
	          if(isProfilePicUpdate) {
	            uploadProfileImage(res, updatedProfile, isSubmit);
	          } else {
	            if(isSubmit) {
	                setStepperView(6);
	            } else {
					insertOrUpdatePurchase(res);
	            }
	          }
	        });
	      } else {
	        setError(true);
	      }
	    })
	    .catch((error) => {
	      console.log('error addService/updateService', error);
	      setLoading(false);
	    });
  	}

  	const isShowSkipButton = () => {
		if(isUpgradingPlan){
			return false;
		}

		if(stepperView !== 6 && userDetails && userDetails?.userId && userDetails?.userId !== "") {
			return true;
		}
	    
	    return false;
  	}

  	const onHandleAcountChange = (e) => {
    	setUserDetails({...userDetails, accountDetails : {...userDetails.accountDetails, [e.target.name] : e.target.value}});
  	}

  	const {
		role = "customer",
		name = "",
		phone = "",
		email = "",
		address = {},
		accountDetails = {},
		catalystDetails = {},
		paymentDetails = {},
		profileDescription = {},
  	} = userDetails || {};


	
	  const {
		locations = [
		  {
			address: "",
			city: "",
			country: "",
			coordinate: {},
		  },
		],
		languages = [],
		serviceCategory = [],
		planDetails = {},
	  } = catalystDetails || {};
	
	  const {
		subscriptionId = "",
		subscriptionName = "",
		subscriptionPaymentPlan = "",
		subscriptionPrice = "",
		subscriptionTotalPrice = 0,
		subscriptionPriceCurrency = "",
		subscriptionTrialDuration = "",
		subscriptionPaymentDuration = "",
	  } = planDetails || {};

	
  	const {
  		publicName = "",
  		languageSpeak = [],
  		interestCategories = [],
  		stuggleCategories = []
  	} = profileDescription || {};

  	const {
  		taxNumber = ""
  	} = accountDetails || {};

  	const {
	    street = "",
	    city = "",
	    state = "",
	    apartment = "",
	    pincode = "",
	    country = ""
  	} = address || {};

  	const isJump = false;
  	const isSkip = isJump;

  	const formattedRole = covertToLower(role);
  	const submitText = getBoardingCTAText(stepperView, formattedRole, isSkip);
  	const showBackBtn = isShowBackBtn(stepperView, formattedRole, "ON_BOARDING");
  	const title = getBoardingHeaderText(stepperView, formattedRole);

	return(
		<>
			<DialogActions className="w-100 p-4">
		        <div className="w-100 d-flex justify-content-between align-items-center px-0 px-lg-5 px-md-3">
		            <div>
		              {(showBackBtn && navigateStepper < stepperView) && <button
		                onClick={handleBack}
		                aria-label="Back"
		                className="ax-button color-primary-50"
		              >
		                <ArrowBack className = "color-primary-50" />
		              </button>
		            }
		            </div>

		            <h4 className="heading-4 color-primary-50">
		              {title}
		            </h4>

		            <div>
		              <button     
		                onClick={onHandleClose}
		                aria-label="close"
		                className="close_icon ax-button"
		              >
		                <img src={brown_cancel} className="close_icon" alt="Close" />
		              </button>
		            </div>
		        </div>
		    </DialogActions>
	        
	        <DialogContent>
			{stepperView === 1 && (
          <GuidelineInfo
            userDetails={userDetails}
            isGuidelineChecked={isGuidelineChecked}
            setGuidelineChecked={(val) => setGuidelineChecked(val)}
            userErrors={userErrors}
          />
        )}

        {stepperView === 2 && (
          <CustomerSelectPlan
          userDetailsPlanId={userDetails.catalystDetails.planDetails.subscriptionId}
            onHandlePlanDetailsChange={onHandlePlanDetailsChange}
          />
        )}

        {stepperView === 3 && (
          <UserDetails
            userDetails={userDetails}
            userErrors={userErrors}
            onHandleChange={(e) => onHandleChange(e)}
            onHandleAddressChange={(e) => onHandleAddressChange(e)}
            onHandleProfileChange={(e) => onHandleProfileChange(e)}
            onHandleProfileImageChange={onHandleProfileImageChange}
            onDropdownChange={(name, list) => onDropdownChange(name, list)}
            newProfilePic={newProfilePic}
          />
        )}
        {stepperView === 4 && (
          <CustomerPaymentView
            ref={paymentRef}
            userDetails={userDetails}
            onUpdateBillingAddress={(data) => setBillingAddress(data)}
          />
        )}

        {stepperView === 5 && (
          <>
            <iframe
              title="Payment view"
              id="iframe"
              src="../../../../../samplesibs.html"
              width="100%"
              height="90%"
              frameBorder="0"
              allow="geolocation"
            ></iframe>
          </>

         
        )}

        {stepperView === 6 && (
           <AddObjective
           userDetails={userDetails}
           userErrors={userErrors}
           onHandleChange={(e) => onHandleChange(e)}
           onDropdownChange={(name, list) =>
             onObjectiveDropdownChange(name, list)
           }
           onHandleAcountChange={(e) => onHandleAcountChange(e)}
         />
        )}

        {stepperView === 7 && (
          <Confirmation
            userDetails={userDetails}
            userErrors={userErrors}
            onHandleChange={(e) => onHandleChange(e)}
            onHandleAllService={onHandleAllService}
          />
        )}
	        </DialogContent>

	        <DialogActions className="d-flex justify-content-between align-items-center bg-white">
	          <div className="container-fluid">
	            <div className=" d-flex justify-content-between align-items-center dialog_padding">
	              <div className="d-flex"></div>
	              
	              {stepperView !== 7 && <div className="d-flex">
	                {isShowSkipButton()  && <button className="me-3 tertiary_btn capitalize color-primary-50" onClick={onHandleSkipSubmit}>Skip & submit</button>}
	                <button className="secondary_btn capitalize" onClick={goNext}>{submitText}</button>
	              </div>
	          		}
	            </div>
	          </div>
	        </DialogActions>

	        {isLoading && <Loading />}
      		{isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
        </>
	)
}

export default CustomerOnBoarding;