import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams } from 'react-router-dom';
import HelmetDetails from "../../components/HelmetDetails";
import HelmetText from '../../components/helmet-text/HelmetText';
import PurchaseDialog from './servicePurchaseDialog/PurchaseDialog';
import ScheduleDialog from './scheduleDialog/ScheduleDialog';
import AuthDialog from '../../components/authenticate/AuthDialog';
import MapView from '../../components/map/MapView';
import ImageCarousel from '../../components/image-carousel/ImageCarousel';
import {getTimeZoneKey} from '../../utils/DefaultStates';
import {getAuthenticateHeader, getMinimumTicketPrice, getDateFromEpoc, isScheduleExpired} from '../../utils/util';
import {headerOption} from '../../utils/DefaultInitialValues';

import CustomizedSnackbars from  '../../components/CustomizedSnackbars';
import DialogView from '../../components/dialog/DialogView';
import Loading from '../../components/loading/Loading';
import OnBoardingDialog from '../admin/onBoardingAdmin/OnBoardingDialog';

import pic_1 from '../../assets/avatar.png';
import service_placeholder from '../../assets/img_placeholder.png'; 
import location_pin from '../../assets/Location pin.png';
import {baseURL, mindedClubUserId, mindersBasicPlanTitle} from '../../actions/Constants';
import {saveAdmin} from '../../actions/admin.actions';
import {isWithin30MinutesAndBeforeEndDateEpoch} from '../../utils/util';


const ScheduleDetails = (props) => {
  const dispatch = useDispatch();
  const saveAdminAction = saveAdmin;

	const [isShowDialog, setShowDialog] = useState(false);
	const [isShowLoginDialog, setShowLoginDialog] = useState(false);
	const [isShowScheduleDialog, setShowScheduleDialog] = useState(false);
	const [isToShowPaymentPlanDetails, setShowPaymentPlanDetails] =  useState(false);
	const [isEventLinkNotAccessible, setIsEventLinkNotAccessible] = useState(false);
	const [formattedDate, setFormattedDate] = useState('');
	const [formattedTime, setFormattedTime] = useState('');
	
	const [navigateStepper, setNavigateStepper] = useState(0);
	const [isLoading, setLoading] = useState(false);
	const [serviceDetails, setServiceDetails] = useState({});
	const [scheduleDetails, setScheduleDetails] = useState(null);

	const [catalystDetails, setCatalystDetails] = useState({});
	const [purchaseId, setPurchaseId] = useState("");
	const [isShowAboutDialog, setShowAboutDialog] = useState(false);
	const [isExpired, setExpired] = useState(false);

	const [isError, setError] = useState(false);
	const { name:scID } = useParams();

	const {
    	details: loggedUser
  	} = useSelector(({ loggedAdmin: { details} }) => ({ details }));

	const getCatalystById = async (id = "") => {
		setLoading(false);
		const URL = `${baseURL}/minded-user/getUserById/${id}`;

       	return await fetch(URL, headerOption)
      	.then(response => {
		    const resp = response;

		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200 || status === 201) {
		    	return response.json().then(res => { 
		       	setCatalystDetails(res);
		    	});
		    } else {
	      		setError(true);
		    }
		})
	  	.catch((error) => {
	    	console.log('error getServiceById', error);
		    setError(true);
		});
	}

	const onLoginSuccess = (isSignupView, userinfo) => {
		dispatch(saveAdminAction(userinfo));
		setShowDialog(true);
	}

	const onHandleScheduleNow = (purchaseInfo = {}) => {
		//setPurchaseId(purchaseInfo?.id);
		//setPurchaseId("123");
		setShowScheduleDialog(true);
	}

	useEffect(() => {
		window.scrollTo({top: 0, behavior: "smooth"});
		getScheduleById();
	}, []);

	useEffect(() => {
    	const isExp = isScheduleExpired(scheduleEndTime)
      	setExpired(isExp);
	}, [scheduleDetails]);

    const onHandleBookNow = () => {
    	if(isUsedLoggedIn) {
			if(isThisTheMindedClubProfileDetails){
				console.log(scheduleDetails, scheduleDetails)
				onHandldeMindedClubEventClick(scheduleDetails);
			}else{
				setShowDialog(true);
			}
		} else {
			setShowLoginDialog(true);
		}
    }
	
	const planDetails = loggedUser?.catalystDetails?.planDetails;
	const userAtualPlanName = planDetails?.subscriptionName;
	const subscriptionStatus = planDetails?.subscriptionStatus;
	const userStatusActive = loggedUser?.status === "active" 
	const userHasBasicPlan = !userStatusActive || (userStatusActive && userAtualPlanName === mindersBasicPlanTitle) 
								|| ( userAtualPlanName !== mindersBasicPlanTitle && subscriptionStatus === "auth_failed");
	

	const onHandleSubscribeClick = (item) => {
		setNavigateStepper(2);
		setShowPaymentPlanDetails(true);
	}
	const onHandldeJoinNowClick = (item) => {
		if(!isWithin30MinutesAndBeforeEndDateEpoch(item.scheduleStartTime, item.scheduleEndTime)){ // if it is expired the button will be disabled
			const sessionDate = new Date(item.scheduleStartTime);
			setFormattedDate(sessionDate.toLocaleDateString('pt-PT'));
			setFormattedTime(sessionDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
			setIsEventLinkNotAccessible(true);
		} else {
			window.open(item?.serviceDetails?.serviceScheduleDetails?.serviceMeetingLink, "_blank");
		}
	}

	const onHandldeMindedClubEventClick = (item) => {
		if (loggedUser.status !== "active"  || userHasBasicPlan) {
			onHandleSubscribeClick();
		}else{
			onHandldeJoinNowClick(item);
		}	
    }


    const getScheduleById = async () => {
    	setLoading(false);
		const URL = `${baseURL}/minded-services/getScheduleDetails?scheduleId=${scID}`;

		const myHeaders = getAuthenticateHeader();
 		
      	return await fetch(URL, headerOption)
      	.then(response => {
		    const resp = response;

		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200 || status === 201) {
		    	return response.json().then(res => {
		    		const scDetails = res && res[0]; 
		       		setScheduleDetails(scDetails);
		       		setServiceDetails(scDetails?.serviceDetails);
		       		getCatalystById(scDetails?.userId);
		       		
		    	});
		    } else {
	      		setError(true);
		    }
		})
	  	.catch((error) => {
	    	console.log('error getScheduleById', error);
		    setError(true);
		});
    }

    const getCTALabel = (isExpired = false, avlCount = 0, label = "") => {
	    if(isExpired) return "Expired";
	    else if(avlCount === 0) return "Sold Out";

	    return label;
	}

	const isDisabledBtn = () => {
		if(isExpired || availableSeatCount === 0) {
			return true;
		}
		return false;
	}

    const minTicketPrice = getMinimumTicketPrice(serviceDetails);

    const isUsedLoggedIn = loggedUser && loggedUser?.userId;

    const {
    	startTime = "",
    	endTime = "",
    	serviceType = "",
		serviceName = "",
		scheduleMode = "online",
		serviceLocation = {},
		serviceDescription = "",
		serviceImg = [],
		serviceScheduleDetails = {},
		serviceTimezone = ""
    } = serviceDetails || {};

    const {
    	scheduleDate = "",
		availableSeatCount = 0,
		scheduleEndTime = "",
		scheduleStartTime = "",
		scheduleBookedCount = -1,
		scheduleMaxParticipants = 0
    } = scheduleDetails || {};

    const {
    	name = "",
    	profileDescription = {}
    } = catalystDetails || {};

    const {
    	aboutMe = "",
    	publicName = "",
    	profilePic = ""
    } = profileDescription || {};

	const isThisTheMindedClubProfileDetails = catalystDetails.userId === mindedClubUserId;
    const feature_array = catalystDetails && catalystDetails?.catalystDetails?.serviceCategory && catalystDetails?.catalystDetails?.serviceCategory?.map((item) => item?.name);
    const descLength = aboutMe ? aboutMe.length : 0;

    const CTALabel = !isThisTheMindedClubProfileDetails ? "Book Now" 
													: (userHasBasicPlan ? 'SUBSCRIBE' : 'JOIN');

    const CTALabelValue = getCTALabel(isExpired, availableSeatCount, CTALabel);
	const isDisabled = isDisabledBtn();

	return (
		<>
			<HelmetDetails title="Service Details | Minded." />
			<HelmetText H1text = {serviceName || ""} />
			<div className="main-no-content-padding">

				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-8">
							
							<div className="urban-card color-secondary-90_bg rounded-3 p-lg-5 p-3">
								<div className="row w-100">
									<div className="col-6 col-md-6">
										<div>
											<div className="py-1">
												<div className="color-primary-50 body-R py-1">Date</div>
												<div className="color-primary-50 subtitle-R">{getDateFromEpoc(scheduleDate)}</div>
											</div>

											<div className="color-primary-50 body-R py-1">Timing</div>
											<div className="timing_container w-100">
												{serviceType !== "PRIVATE" &&  <div className="color-primary-50 subtitle-R">{startTime} - {endTime} {getTimeZoneKey(serviceTimezone)}</div>}
												{serviceType === "PRIVATE" && serviceScheduleDetails?.serviceScheduleTypeConfig.map((eachDate, index) => {
													return (
														<>
															<div className="color-primary-50 body-B">Every {eachDate?.day}</div>
															{eachDate?.dayWiseTimeList?.map((daywiseTime, index) => 
																<div>
																	<div className="">
																		<div className="body-CAP capitalize py-2 color-primary-50">{daywiseTime?.startTime} - {daywiseTime?.endTime || "-"} {getTimeZoneKey(serviceTimezone)}</div>
																	</div>
																</div>
															)}
														</>
													)}
												)}
											</div>
											
										</div>
									</div>
									<div className="col-6 col-md-6">
										<div>
											<div className="color-primary-50 body-R py-1">Event Mode</div>
											<div className="color-primary-50 body-R py-1">{serviceScheduleDetails?.serviceMode?.toUpperCase()}</div>
											
											{serviceScheduleDetails?.serviceMode === "physical" && 
												<div className="color-primary-50 subtitle-R">
													<div className="color-primary-50 body-R py-1">Event at</div>
													<img src={location_pin} style={{width: '24px', height: '24px'}} alt = "" />
													{serviceLocation?.venueName ? `${serviceLocation?.venueName}, ${serviceLocation?.address?.label}. ` : serviceLocation?.address?.label}
												</div>
											}

											{/*
											serviceScheduleDetails?.serviceMode === "online" && <div className="color-primary-50 subtitle-R">
												Online
											</div>
											*/}
										</div>
									</div>
								</div>

								<div className="row w-100 align-items-center">

									<div className="col-lg-6 col-md-6 mt-4">
									{!isThisTheMindedClubProfileDetails && 
										<div>
											<div className="color-primary-50 body-R py-1" >Ticket from</div>
											<div className="color-primary-50 subtitle-R">{minTicketPrice || "-"}</div>
										</div>
									}
									</div>
									<div className="col-lg-6 col-md-6 mt-4">
										<div className="w-100 d-flex align-items-center">
											<button 
												disabled={isDisabled} 
												className="w-xs-100 secondary_btn rounded-pill" 
												onClick={onHandleBookNow}>{CTALabelValue || "Book Now"}
											</button>
										</div>
									</div>
								</div>
							</div>

							<div className="urban-card my-4 bg-white rounded-3 p-lg-5 p-3">
								<div className="w-100">
									<div className="w-100">
										<h4 className="heading-4 color-primary-50">About</h4>
									</div>
								</div>
								
								<div className="w-100 responsive_flex my-2 justify-content-between my-2">
									<div className="w-100 event_description_container">
										<div className="py-3 body-R color-primary-50">{serviceDescription || "-"}</div>
										
										{/*<button className="tertiary_btn my-2 color-primary-50">READ MORE</button>*/}
									</div>

									<div className="w-100 px-2 event_description_container">
										{serviceImg.length !== 0 && <ImageCarousel images={serviceImg} />}
										{serviceImg.length === 0  && <img src = {service_placeholder} className="w-75 m-auto p-2" alt = "" />}
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
        					<div className="m-auto">
								<div className="w-100 text-center">
									<img src={profilePic || pic_1} className="w-100 rounded-3" style={{maxHeight: "400px"}} alt="" />
									<div className="pt-3 body-B color-primary-50">{feature_array && feature_array.length > 0 && feature_array.join(" | ") || "-"}</div>
									<div className="body-CAP py-1 color-primary-50">{publicName || name}</div>
									
									<div className="text-justify px-3">
										<div className="color-primary-50 body-R py-2 text-justify">
											{descLength > 100 ? aboutMe.slice(0, 100) : aboutMe}
											{descLength > 100 && <span>...</span>}

										</div>
										{descLength > 100 && <div className="d-flex justify-content-center"><button className="tertiary_btn my-2 color-primary-50" onClick={() => setShowAboutDialog(true)}>
												Read More
											</button>
										</div>
										}
									</div>
								</div>
        					</div>
						</div>

					</div>
				</div>
				
				
				{serviceScheduleDetails?.serviceMode === "physical" && <div className="container-fluid">
					<MapView serviceLocation = {serviceLocation} />
				</div>}
			</div>

			{isShowLoginDialog && <AuthDialog 
				isOpen = {isShowLoginDialog} 
				onHandleClose = {(val) => setShowLoginDialog(false)} 
				onLoginSuccess = {(isSignupView, userinfo) => onLoginSuccess(isSignupView, userinfo)}
			/>}

			{isShowDialog && <PurchaseDialog 
				isOpen = {isShowDialog}
				scheduleDetails = {scheduleDetails} 
				onHandleClose = {() => setShowDialog(false)} 
				onHandleScheduleNow = {(purchaseInfo) => onHandleScheduleNow(purchaseInfo)}
			/>}

			{isShowScheduleDialog && <ScheduleDialog 
				isOpen = {isShowScheduleDialog} 
				onHandleClose={() => setShowScheduleDialog(false)}
				purchaseId = {purchaseId}
			/>}
			{isToShowPaymentPlanDetails && <OnBoardingDialog 
				isUpgradingPlan = {true}
				isOpen = {isToShowPaymentPlanDetails}
				userinfo = {loggedUser}
				onHandleClose={() => setShowPaymentPlanDetails(false)} 
				navigateStepper = {navigateStepper} />
			}

			{isShowAboutDialog && <DialogView onHandleClose = {() => setShowAboutDialog(false)} show ={isShowAboutDialog} about = {profileDescription?.aboutMe} />}
			{isLoading && <Loading />}
			{isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
			{isEventLinkNotAccessible && <CustomizedSnackbars
				text={`This session starts on ${formattedDate} at ${formattedTime}. The link will be available 30 minutes before the session begins,`} 
				alertSeverity="warning" vertical="top" autoHideDuration={6000} 
				showAlert={(_) => setIsEventLinkNotAccessible(false) } 
			/>}
			
		</>
	)
}

export default ScheduleDetails;