import {useEffect, useRef} from 'react';
import Input from '../../../components/input/Input';
import FloatSelectView from '../../../components/floatSelectView/FloatSelectView';
import Textarea from '../../../components/textarea/Textarea';
import MultiSelectDropdown from '../../../components/multi-select-dropdown/MultiSelectDropdown';
import {LangList, CountryList} from '../../../utils/DefaultStates';
import img_placeholder from '../../../assets/img_placeholder.png';

const UserDetails = (props) => {
  const dialogRef = useRef();
	const {userDetails = {},  userErrors = {}, onHandleChange, onHandleAddressChange, onHandleProfileImageChange, onHandleProfileChange, onDropdownChange, newProfilePic = ""} = props;

  const {
    name = "",
    phone = "",
    role = "",
    profileDescription = {},
    address = {}
  } = userDetails || {};

  const {
    profilePic = "",
    publicName = "",
    aboutMe = "",
    languageSpeak = []
  } =  profileDescription || {};

  const {
    street = "",
    apartment = "",
    city = "",
    state = "",
    pincode = "",
    country = ""
  } = address || {};

  const {
    nameError = false,
    phoneError = false,
    countryError = false,
    streetError = false,
    apartmentError = false,
    cityError = false,
    pincodeError = false,
    stateError = false,
    publicNameError = false,
    aboutError = false,
    languageSpeakError = false,
    profileImageError = false
  } = userErrors || {};

  useEffect(() => {
    try {
      dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } finally {
    }
  }, []);

  const isCatalyst = (role === "catalyst");

	return (
		<>
      <div className="container dialog_padding" ref={dialogRef}>
      	<div className="row">
      		<div className="col-lg-6 m-auto">
            <div className="w-100 card-view bg-white flex_container_40 bg-white padding_70 border_50 my-3">
              <h5 className="heading-5 color-primary-50">1.1 General</h5>
                <div className="mb-2 w-100">
                  <Input
                    name="name"
                    label="Full Name"
                    placeHolder="John Deo" 
                    value={name}
                    isRequired = {true}
                    onInputChange={(e) => onHandleChange(e)} 
                  />
                  {nameError && <div className="text-danger">! Name is a required field</div>}
                </div>

                <div className="mb-2 w-100"> 
                  <Input
                    name="phone"
                    label="Phone (with area code)"
                    placeHolder="xxx-xxx-xxxx" 
                    value={phone}
                    isRequired = {true}
                    onInputChange={(e) => onHandleChange(e)} 
                  />
                  {phoneError && <div className="text-danger">! Phone is a required field</div>}
                </div>
      			</div>

            <div className="w-100 card-view bg-white flex_container_50 bg-white padding_70 my-3 border_50">
              <h5 className="heading-5 color-primary-50">1.2 {isCatalyst ? "Billing Address" : "Address"}</h5>
              
              <div className="w-100">
                <Input
                  name="street"
                  label="Street Address"
                  value={street}
                  isRequired={true}
                  onInputChange={(e) => onHandleAddressChange(e)}
                />
                {streetError && <div className="text-danger">! Street is a required field</div>}
              </div>

              <div className="w-100">
                <Input
                  name="apartment"
                  label="Apartment/Suite/Unit Number"
                  value={apartment}
                  isRequired={true}
                  onInputChange={(e) => onHandleAddressChange(e)}
                />
                {apartmentError && <div className="text-danger">! Apartment is a required field</div>}
              </div>

              <div className="responsive_flex w-100">
                <div className="mb-2 width-50 me-2">
                  <Input
                    name="city"
                    label="City"
                    value={city}
                    isRequired={true}
                    onInputChange={(e) => onHandleAddressChange(e)} 
                  />
                  {cityError && <div className="text-danger">! City is a required field</div>}
                </div>
               
                <div className="mb-2 width-50">
                  <Input
                    name="state"
                    label="State"
                    value={state}
                    isRequired={true}
                    onInputChange={(e) => onHandleAddressChange(e)} 
                  />
                  {stateError && <div className="text-danger">! State is a required field</div>}
                </div>
              </div>

              <div className="responsive_flex w-100">
                 <div className="mb-2 width-50 me-2">
                  <Input
                    name="pincode"
                    label="Zip/postal code"
                    value={pincode}
                    isRequired={true}
                    onInputChange={(e) => onHandleAddressChange(e)} 
                  />
                  {pincodeError && <div className="text-danger">! Pincode is a required field</div>}
                </div>

                <div className="mb-2 width-50 select">
                  <FloatSelectView
                    name = "country"
                    label = "Country"
                    list = {CountryList}
                    value = {country}
                    isRequired={true}
                    onInputChange = {e => onHandleAddressChange(e)} 
                  />
                  {countryError && <div className="text-danger">! Country is a required field</div>}
                </div>
              </div>
            </div>

            <div className="w-100 card-view bg-white flex_container_40 bg-white padding_70 my-3 border_50">
              <h5 className="heading-5 color-primary-50">1.3 About me</h5>
              <div className="mb-4 w-100">
                <Input
                  name="publicName"
                  label="Nick name (as you want it on your profile)"
                  value={publicName}
                  onInputChange={(e) => onHandleProfileChange(e)} 
                />
                {publicNameError && <div className="text-danger pt-1">! Nick Name must be small</div>}
              </div>
              
              <div className="my-3 w-100">
                <Textarea 
                  className="w-100" 
                  rows="4" 
                  cols="50" 
                  label="About me"
                  placeholder="Tell us about yourself in 300 words." 
                  name="aboutMe"
                  value={aboutMe}
                  maxlength = {3000}
                  isRequired={isCatalyst}
                  onInputChange={(e) => onHandleProfileChange(e)} 
                />
                
                <label className="color-primary-50 opacity-75">Tell us about yourself in 300 words</label>
                {aboutError && <div className="text-danger">! About me is a required field</div>}
              </div>

              <div className="my-3 w-100">
                <label for="languageSpeak" className="heading-6 color-primary-50">Languages I speak *</label>
                <div className="my-1 w-100 select">
                  <MultiSelectDropdown 
                    name="languageSpeak" 
                    id="languageSpeak" 
                    selectedValue = {languageSpeak} 
                    onDropdownChange = {onDropdownChange} 
                    options = {LangList}
                    viewLabel = "value"
                  />
                </div>
                {languageSpeakError && <div className="text-danger">! Language Speak is a required field</div>}
              </div>

            </div>

            <div className="w-100 card-view bg-white flex_container_40 bg-white padding_70 my-3 border_50">
              <h5 className="heading-5 color-primary-50">1.4 Public image photo {isCatalyst && <span>*</span>}</h5>
              
              <div className="responsive_flex my-4 w-100">
                <div className="profile_img_container">
                  {(newProfilePic === "" && profilePic === "") && <img src={img_placeholder} className="w-100 h-100" alt = "" />}
                  {(newProfilePic !== "" || profilePic !== "") && <img className="profile_img_container" src = {(typeof(newProfilePic)) !== 'string' ? URL.createObjectURL(newProfilePic) : profilePic} alt = "" />}
                </div>

                <form className="mx-3 my-3 w-100 d-flex justify-content-center" method="post" encType="multipart/form-data">
                  <input id="upload" type="file" className="ax-button a11wy profile_img" onChange={onHandleProfileImageChange} accept=".jpg, .jpeg, .png" />
                  <label for="upload">
                    <span type="button" className="tertiary_btn rounded-pill color-primary-100 p-3">CHOOSE IMAGE</span>
                  </label>
                </form>
              </div>
              {profileImageError && <div className="text-danger">! Profile Image is a required field</div>}
              
              <div>
                <h5 className="heading-5 color-primary-50">Photo Guidelines</h5>
                <div className="responsive_flex py-2">
                  <ul className="width-100 body-R color-primary-20">
                    <li>At least 250x250 pixels</li>
                    <li>JPG, PNG formats only</li>
                    <li>Maximum size of 2MB</li>
                  </ul>
                </div>
              </div>
              
            </div>

      		</div>
      	</div>
      </div>
    </>
	)
}

export default UserDetails;