import React, {useEffect, useState} from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import UserProfile from '../user-profile/UserProfile';
import AuthDialog from '../authenticate/AuthDialog';
import OnBoardingDialog from '../../pages/admin/onBoardingAdmin/OnBoardingDialog';
import {GeneralNavLinks, Logged_Nav_Links, InitialUserDetails} from "../../utils/DefaultInitialValues";
import {getNavDetails} from '../../utils/util';
import yellow_logo_with_text from '../../assets/yellow_logo_with_text.svg';
import green_logo_with_text from '../../assets/green_logo_with_text.svg';
import White_Logo_with_text from '../../assets/White_Logo_with_text.svg';

import {removeCookie} from '../../utils/util';
import {saveAdmin} from '../../actions/admin.actions';

import './header.css';

const Header = () => {
    const [isShow, setShow] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isLoginBtnClick, setLoginBtnClick] = useState(false);
    const [isShowLoginDialog, setShowLoginDialog] = useState(false);
    const [isShowUserMenu, setShowUserMenu] = useState(false);
    const [headerStyleDetails, setHeaderStyleDetails] = useState({});
    const [searchParam, setSearchParam] = useSearchParams()
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const [Logo, setLogo] = useState("");
    const [menuColor, setMenuColor] = useState("color-accent-90");

    const [userDetails, setUserDetails] = useState(InitialUserDetails);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const saveAdminAction = saveAdmin;

    const {
       details: loggedUser
    } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

    useEffect(() => {
        window.addEventListener("scroll", function() {
            var header = document.querySelector(".header");
            if(header) {
                header.classList.toggle("sticky", window.scrollY > 250);
            }
        });
    }, []);

    useEffect(() => {
        let locationVal = location?.pathname;
        let navStyleDetails = getNavDetails(locationVal);
        setHeaderStyleDetails(navStyleDetails);
    }, [location]);

    useEffect(() => {
        if(loggedUser && loggedUser?.userId &&  loggedUser?.userId !== "") {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }, [loggedUser]);

    const handleMenuClick = () => {
        let menuIcon = document.querySelector('#menu-icon');
        let navbar = document.querySelector('.navbar1');
        menuIcon.classList.toggle('close');
        navbar.classList.toggle('active');
        getLogo();
    }

    const handleNavLinkClick= () => {
        let menuIcon = document.querySelector('#menu-icon');
        let navbar = document.querySelector('.navbar1');
        menuIcon.classList.remove('close');
        navbar.classList.remove('active');
    }

    const onLoginBtnClick = () => {
        setLoginBtnClick(true);
    }

    const onHandleClose = () => {
        let menuIcon = document.querySelector('#menu-icon');
        let navbar = document.querySelector('.navbar1');
        menuIcon.classList.remove('close');
        navbar.classList.remove('active');
    }

    const onItemClick = (path = "") => {
        let menuIcon = document.querySelector('#menu-icon');
        let navbar = document.querySelector('.navbar1');
        menuIcon.classList.remove('close');
        navbar.classList.remove('active'); 

        if(path === "logout") {
            //do logout work
            removeCookie("token");
            setLoggedIn(false);
        }
    }

    const onLoginSuccess = (isSignupView = false, userinfo = {}) => {
        setShowLoginDialog(false);
        setLoginBtnClick(false);
        handleNavLinkClick();

        if(isSignupView) {
            setUserDetails(userinfo);
            setShow(true);
        } else {
            setLoggedIn(true);
            dispatch(saveAdminAction(userinfo));
            navigate("/dashboard");
        }
    }

    const navLinks = isLoggedIn ? GeneralNavLinks : GeneralNavLinks;
    const {
        PATH = "/",
        NAV_LINK_LOGO = "",
        NAV_BG_COLOR = "fixed",
        NAV_LINK_COLOR = "color-accent-90",
        ACTIVE_NAV_LINK_COLOR = "color-accent-90",
        ACTIVE_LINK_UNDERLINE_COLOR = "color-accent-90",
        LOGIN_BG_COLOR = "color-accent-90",
        LOGIN_TEXT_COLOR = "color-primary-50",
        H1_TEXT = "Home",
        HEMLMET_BG = "",
        BREADCRUMB_LINK_COLOR = "",
        BREADCRUMB_SEPERATOR_COLOR = "",
        activeIndex = 0,
        LOGO = "yellow_logo",
        HAMBURGER_COLOR = "color-accent-90"
    } = headerStyleDetails || {};


    useEffect(() => {
        getLogo();
    }, [LOGO]);

    useEffect(() => {
        setMenuColor(HAMBURGER_COLOR);
    }, [HAMBURGER_COLOR])

    const getLogo = () => {
        let logo_img = green_logo_with_text;

        let navbar = document.querySelector('nav');
        if(activeIndex === 2 &&  navbar && navbar.classList.contains('active')) {
            logo_img = White_Logo_with_text;
        } else {
            if(LOGO == "yellow_logo") {
                logo_img = yellow_logo_with_text;
            } else if(LOGO == "white_logo") {
                logo_img = White_Logo_with_text;
            }
        }

        setLogo(logo_img);
    }

    return(
        <>
            <header className={`header ${NAV_BG_COLOR}`}>
                <Link to="/" className={`logo ${NAV_LINK_LOGO}`}>
                    <img src={Logo} className="w-50" />
                </Link>
                {/*
                    <div className="d-flex"> 
                    </div>
                */}

                <div className="d-flex align-items-center justify-content-center">
                    <nav className="navbar1">
                        <div className="mobile_view">
                            <Link to="/" className={`logo ${NAV_LINK_LOGO}`}>
                                <img src={Logo} className="w-50" />
                            </Link>
                        </div>

                        <div className="py-3 navLink-container">
                            {navLinks.map((link, index) => {
                                return link?.type == "Select" ? (
                                  <div className="navlink-options" onClick={()=> setIsMenuOpen(!isMenuOpen)}>
                                    <span className={`${NAV_LINK_COLOR} LINK capitalize`}>
                                      {link.title}
                                    </span>
                                    {isMenuOpen && (
                                      <ul className='ul-container'>
                                       {link?.options.map(option=>{
                                        return (
                                          <li
                                          onClick={()=>navigate(`${link.path}?search=${option.value}`)}
                                            className={`link-item ${
                                              searchParam.get("search") ===
                                              option.value
                                                ? "selected-item"
                                                : ""
                                            }`}
                                          >
                                            <Link
                                              onClick={handleNavLinkClick}
                                              className={`capitalize ${
                                                searchParam.get("search") ===
                                                option.value
                                                  ? "selected-item-link"
                                                  : ""
                                              }`}
                                              to={`${link.path}?search=${option.value}`}
                                            >
                                              {option.label}
                                            </Link>
                                          </li>
                                        );
                                       })}
                                      </ul>
                                    )}
                                  </div>
                                ) : (
                                  <Link
                                    to={link.path}
                                    onClick={handleNavLinkClick}
                                    data-color={`${NAV_LINK_COLOR}`}
                                    className={`${NAV_LINK_COLOR} LINK capitalize ${
                                      activeIndex === index ? "active" : ""
                                    }`}
                                  >
                                    {link.title}
                                  </Link>
                                );
                            }
                            )}
                        </div>
                        
                        <div className="mobile_view py-3">
                            {!isLoggedIn && <button 
                                className={`ax-button white-text LINK capitalize ${NAV_LINK_COLOR}`}
                                onClick={() => setShowLoginDialog(true)}>
                                Login/Signup
                            </button>}
                             
                            {isLoggedIn && <Link to="/logout" className="color-accent-90 LINK capitalize">
                                <div onClick={() => handleMenuClick()} className="LINK">Logout</div>
                            </Link>}
                        </div>

                        <div className="mobile_view py-3">
                            <div className="py-3">
                                <div><a href="https://www.instagram.com/theminded.club/" target="_blank">Instagram</a></div>
                                <div><a href="https://www.facebook.com/theminded.club23" target="_blank">Facebook</a></div>
                                <div><a className="mail" href="mailto:hello@theminded.club">hello@theminded.club</a></div>
                                
                            </div>
                        </div>
                    </nav>

                    {isLoggedIn && <UserProfile 
                        LOGIN_BG_COLOR = {LOGIN_BG_COLOR} 
                        LOGIN_TEXT_COLOR = {LOGIN_TEXT_COLOR} 
                        onMenuItemClick = {(path) => onItemClick(path)} 
                        userDetails = {loggedUser} 
                    />}
                    
                    {!isLoggedIn && <span className="mx-3 desktop_view">
                        <button 
                            className={`LINK capitalize login_btn ${LOGIN_BG_COLOR} ${LOGIN_TEXT_COLOR}`}
                            onClick={() => setShowLoginDialog(true)}>
                            Login
                        </button>
                    </span>
                    }
                    <i className={`bx bx-menu ${menuColor}`} id="menu-icon" onClick={handleMenuClick}></i>
                </div>
            </header>

            {isShowLoginDialog && <AuthDialog 
                isOpen = {isShowLoginDialog} 
                onHandleClose={(val) => setShowLoginDialog(false)} 
                onLoginSuccess = {(isSignupView, userinfo) => onLoginSuccess(isSignupView, userinfo)}
            />}

            {isShow && <OnBoardingDialog 
                isOpen = {isShow}
                onHandleClose={() => setShow(false)} 
                setLoggedIn = {val => setLoggedIn(val)}
                userinfo = {userDetails}
            />}
        </>
    )
}

export default Header;
