import React, { useState, useCallback, useEffect } from 'react';  
import { GoogleMap, useJsApiLoader, MarkerF, Circle  } from '@react-google-maps/api';
import { useParams } from 'react-router-dom'; 
import HelmetDetails from "../../components/HelmetDetails";
import { GOOGLE_API } from '../../actions/Constants';

function FullScreenMap() {
  const { lat, lng } = useParams(); 
  const latitude = lat ? parseFloat(lat) : -3.745;
  const longitude = lng ? parseFloat(lng) : -38.523;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API,
  });

  const containerStyle = {
    width: '100%',
    height: '100vh',
  };

  const [map, setMap] = useState(null);
  const [currentCenter, setCurrentCenter] = useState({ lat: latitude, lng: longitude });
  const [zoom, setZoom] = useState(14); 

  const onLoad = useCallback(function callback(map) {
    const center = { lat: latitude, lng: longitude };
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, [latitude, longitude]);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const points = [
    {
      lat: currentCenter.lat,
      lng: currentCenter.lng,
    }
  ];

  useEffect(() => {
    if (map) {
      const newCenter = { lat: latitude, lng: longitude };
      map.panTo(newCenter);
      setCurrentCenter(newCenter); 
      setZoom(12); // Adjust zoom based on location
    }
  }, [latitude, longitude, map]);

  useEffect(() => {
      window.scrollTo({top: 0, behavior: "smooth"});
      document.body.classList.add('full_map');
      return () => {
        document.body.classList.remove('full_map');
      };
  }, []);

  return (
    <>
      <HelmetDetails title="Map | Minded." />
      {isLoaded ? 
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={currentCenter}
          zoom={zoom}
          onUnmount={onUnmount}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
          }}
        >
          {points.map((point, idx) => (
            <React.Fragment key={idx}>
              <MarkerF position={point} />
            </React.Fragment>
          ))}
        </GoogleMap>
      : (<></>)}
    </>
  );
}

export default React.memo(FullScreenMap);
