const UserTypeArray = [
  {
    label: "Are you a USER?",
    value: "customer",
    sub_text: "Become a Minder"
  },
  {
    label: "Are you a SERVICE PROVIDER?",
    value: "catalyst",
    sub_text: "Become a Catalyst"
  }

  /*,
  {
    label: "Partner",
    value: "partner",
    sub_text: "for businesses"
  }
  */
]

const Catalyst_OnBoard_Header_Array = [
  "Check guideline & other info",
  "Select your plan",
  "Add Personal details",
  "Add Profile details",
  "Add payment Address",
  "Add payment details",
  "Confirmation"
];

const Class_Header_Array = [
  "Group Session details",
  "Schedule",
  "Pricing & Payments",
  "Confirmation"
];

const Event_Header_Array = [
  "Event details",
  "Schedule",
  "Optional Settings",
  "Add ticket details",
  "Confirmation"
];

const User_OnBoard_Header_Array = [
  "Check guideline & other info",
  "Add details",
  "Add Objective",
  "Add Payment Address"
]

const Private_Header_Array = [
  "1:1 Session details",
  "Schedule",
  "Pricing & Payments",
  "Confirmation"
];

const Mode_Array = [
  {
    "label": "Offline",
    "value" : "offline"
  },
  {
    "label": "Online",
    "value" : "online"
  },
  {
    "label": "Both",
    "value" : "both"
  }
];

const Service_Status_Array = [
  {
    "label": "Active",
    "value": "active"
  },
  {
    "label": "Cancelled",
    "value": "cancelled"
  },
  {
    "label": "Expired",
    "value": "booking_closed"
  }
]

const MyOrder_Period_Array = [
  {
    "label": "Today",
    "value": "today"
  },
  {
    "label": "Tomorrow",
    "value": "tomorrow"
  },
  {
    "label": "Next Week",
    "value": "next_week"
  },
  {
    "label": "30 Days",
    "value": "30_days"
  },
  {
    "label": "Yesterday",
    "value": "yesterday"
  },
  {
    "label": "Last Week",
    "value": "last_week"
  }
]

const Payment_Duration_Array = [
  {
    value: "Monthly",
    label: "Monthly"
  },
  {
    value: "Quarterly",
    label: "Quarterly"
  },
  {
    value: "Yearly",
    label: "Yearly"
  }
]

const Month_Array = [
  {
    value: 1,
    label: "1"
  },
  {
    value: 2,
    label: "2"
  },
  {
    value: 3,
    label: "3"
  },
  {
    value: 4,
    label: "4"
  },
  {
    value: 5,
    label: "5"
  },
  {
    value: 6,
    label: "6"
  },
  {
    value: 7,
    label: "7"
  },
  {
    value: 8,
    label: "8"
  },
  {
    value: 9,
    label: "9"
  },
  {
    value: 10,
    label: "10"
  },
  {
    value: 11,
    label: "11"
  },
  {
    value: 12,
    label: "12"
  }
]

const Service_Tab_List = [
  {
    "label": "GROUP CLASSES",
    "value" : "CLASS"
  },
  {
    "label": "1:1 SESSIONS",
    "value" : "PRIVATE"
  },
  {
    "label": "EVENTS",
    "value" : "EVENT"
  }
]; 


const Catalyst_Details_Service_Tab_List = [
  {
    "label": "ALL",
    "value" : ""
  },
  {
    "label": "GROUP CLASSES",
    "value" : "CLASS"
  },
  {
    "label": "1:1 SESSIONS",
    "value" : "PRIVATE"
  },
  {
    "label": "EVENTS",
    "value" : "EVENT"
  }
]; 

const SERVICE_TABS_LABEL = {
  PRIVATE: "SESSIONS",
  CLASS: "GROUPE CLASSES",
  EVENT: "EVENTS",
};

const Catalyst_Tab_List = [
  {
    "label": "Active",
    "value" : "active"
  },
  {
    "label": "Pending",
    "value" : "pending"
  },
  {
    "label": "Rejected",
    "value" : "rejected"
  }
];

const Ticket_Tab_List = [
  {
    "label": "Tickets",
    "value" : "Ticket"
  },
  {
    "label": "Packages",
    "value" : "Packages"
  },
  {
    "label": "Plans",
    "value" : "Plans"
  }
];

const ticketQuantityType_array = [
  {
    "label": "Limited",
    "value": "limited"
  },
  {
    "label": "Unlimited",
    "value": "unlimited"
  }
]

const Ticket_Participant_Array = [
  {
    "label": "05",
    "value": 5
  },
  {
    "label": "10",
    "value": 10,
  },
  {
    "label": "20",
    "value": 20
  },
  {
    "label": "50",
    "value": 50
  },
  {
    "label": "Custom",
    "value": -1
  },
]

const Ticket_Price_Method = [
  {
    "value" : "Fixed",
    "label" : "Fixed price"
  },
  {
    "value" : "Variant",
    "label" : "More than one price option"
  },
  {
    "value" : "Pay as you want",
    "label" : "Pay as you want"
  }
]

const Policy_Array = [
  {
    "value" : "allowed",
    "label" : "Allowed"
  },
  {
    "value" : "not-allowed",
    "label" : "Not allowed"
  }
]

const Service_Schedule_Type_Array = [
  {
    "value" : "single",
    "label" : "Single Event"
  },
  {
    "value" : "recurring",
    "label" : "Recurring Events"
  }
]

const Repeat_Options_Array = [
  {
    "value": "weekly",
    "label": "Weekly"
  }
  /*,
  {
    "value": "2",
    "label": "Every 2 weeks"
  },
  {
    "value": "3",
    "label": "Every 3 weeks"
  },
  {
    "value": "4",
    "label": "Every 4 weeks"
  },
  */
];

const WeekList = [
  {
    "value": "sunday",
    "label": "SUN"
  },
  {
    "value": "monday",
    "label": "MON"
  },
  {
    "value": "tuesday",
    "label": "TUE"
  },
  {
    "value": "wednesday",
    "label": "WED"
  },
  {
    "value": "thursday",
    "label": "THU"
  },
  {
    "value": "friday",
    "label": "FRI"
  },
  {
    "value": "saturday",
    "label": "SAT"
  }
];

const Category_List = [
  { "id": "54", "name": "Acupuncture" },
  { "id": "44", "name": "Aromatherapy" },
  { "id": "22", "name": "Art Therapy" },
  { "id": "6", "name": "Ashtanga Yoga" },
  { "id": "65", "name": "Astrology" },
  { "id": "68", "name": "BARRE" },
  { "id": "59", "name": "Ayurvedic Practice" },
  { "id": "5", "name": "Bikram Yoga" },
  { "id": "69", "name": "Business Astrology" },
  { "id": "50", "name": "Bodywork & Energy Healing" },
  { "id": "10", "name": "Breathwork" },
  { "id": "42", "name": "Business Mentoring" },
  { "id": "70", "name": "Coaching" },
  { "id": "37", "name": "Career Coaching" },
  { "id": "56", "name": "Chiropractor" },
  { "id": "31", "name": "Counselling" },
  { "id": "20", "name": "Couples Therapy" },
  { "id": "46", "name": "Crystal Healing" },
  { "id": "15", "name": "Dance" },
  { "id": "27", "name": "Dance/Movement Therapy" },
  { "id": "39", "name": "Doula/Birth Coaching" },
  { "id": "28", "name": "Drama Therapy" },
  { "id": "48", "name": "Energy Healing" },
  { "id": "35", "name": "Executive Coaching" },
  { "id": "71", "name": "Entrepreneurship" },
  { "id": "9", "name": "Face Yoga" },
  { "id": "19", "name": "Family Therapy" },
  { "id": "72", "name": "Fertility" },
  { "id": "38", "name": "Financial Coaching" },
  { "id": "8", "name": "Hatha Yoga" },
  { "id": "41", "name": "Health Coaching" },
  { "id": "66", "name": "Heart Activation" },
  { "id": "61", "name": "Herbalism" },
  { "id": "13", "name": "Holistic Cooking" },
  { "id": "17", "name": "Holistic Nutrition" },
  { "id": "73", "name": "Human Design for Business Strategy" },
  { "id": "47", "name": "Hypnotherapy" },
  { "id": "4", "name": "Kundalini Yoga" },
  { "id": "34", "name": "Leadership Coaching" },
  { "id": "74", "name": "Leadership" },
  { "id": "75", "name": "Life Path Astrology" },
  { "id": "36", "name": "Life Coaching" },
  { "id": "52", "name": "Lymphatic Drainage Massage" },
  { "id": "16", "name": "Martial Arts" },
  { "id": "51", "name": "Massage Therapy" },
  { "id": "76", "name": "Mantra" },
  { "id": "58", "name": "Meditation" },
  { "id": "77", "name": "Movement for Mobility" },
  { "id": "26", "name": "Music Therapy" },
  { "id": "62", "name": "Naturopathy" },
  { "id": "78", "name": "Non-Violent Communication" },
  { "id": "12", "name": "Nutrition" },
  { "id": "40", "name": "Parental Coaching" },
  { "id": "18", "name": "Personal Therapy" },
  { "id": "14", "name": "Personal Training" },
  { "id": "79", "name": "Personal Development" },
  { "id": "11", "name": "Pilates" },
  { "id": "25", "name": "Post-natal Counselling" },
  { "id": "24", "name": "Prenatal Counselling" },
  { "id": "21", "name": "Professional Therapy" },
  { "id": "32", "name": "Psychology" },
  { "id": "53", "name": "Reiki" },
  { "id": "80", "name": "Relationship Coach" },
  { "id": "55", "name": "Reflexology" },
  { "id": "23", "name": "Sexology" },
  { "id": "81", "name": "Shadow Work" },
  { "id": "63", "name": "Shamanism" },
  { "id": "82", "name": "Soft Skills" },
  { "id": "83", "name": "Somatic Work" },
  { "id": "57", "name": "Sound Bath" },
  { "id": "45", "name": "Sound Healing" },
  { "id": "84", "name": "Performance Improvement" },
  { "id": "33", "name": "Speech Therapy" },
  { "id": "30", "name": "Spiritual Counselling" },
  { "id": "43", "name": "Sports Mentorship" },
  { "id": "67", "name": "Tantra" },
  { "id": "64", "name": "Tarot Reading" },
  { "id": "49", "name": "ThetaHealing" },
  { "id": "29", "name": "Trauma Therapy" },
  { "id": "60", "name": "Traditional Chinese Medicine" },
  { "id": "7", "name": "Vinyasa Yoga" },
  { "id": "85", "name": "Well-Being" },
  { "id": "3", "name": "Yin Yoga" },
  { "id": "1", "name": "Yoga" },
  { "id": "2", "name": "Yoga Nidra" }
]

const Price_Methods_List = [
  {
    "value" : "session",
    "label" : "Per Session"
  },
  {
    "value" : "package",
    "label" : "Package"
  },
  {
    "value" : "plan",
    "label" : "Monthly Plan"
  },
  {
    "value" : "all",
    "label" : "More than one Pricing Method"
  }
]

const Max_Schedule_List = [
  {
    "value" : 1,
    "label" : "1"
  },
  {
    "value" : 2,
    "label" : "2"
  },
  {
    "value" : 3,
    "label" : "3",
  },
  {
    "value" : 4,
    "label" : "4"
  },
  { 
    "value" : 5,
    "label" : "5"
  }
];

const Location_Options_Array = [
  {
    "value": "physical",
    "label": "Physical Space"
  },
  {
    "value": "online",
    "label": "Online"
  }
];

const InitialUserDetails = {
  role : "customer",
  email : "",
  status : "pending",
  name : "",
  phone : "",
  address : {
    street : "",
    apartment: "",
    city : "",
    state: "",
    pincode : "",
    country : "",
    coordinate : {lat: "", lng: ""}
  },
  accountDetails: {
    street1: "",
    street2: "",
    postcode: "",
    city: "",
    country: "",
    taxNumber: ""
  },
  profileDescription : {
    profilePic : "",
    companyDetails : {
      companyName: "",
      url: ""
    },
    socialMediaHandles : [
      {
        name:"facebook",
        link: ""
      },
      {
        name :"instagram",
        link:""
      }
    ],
    interestCategories: [],
    stuggleCategories: [],
    languageSpeak : [],
    qualificationDetails : {},
    publicName : "",
    aboutMe : ""
  },
  catalystDetails : {
    planDetails : {},
    languages : [],
    mode: "both",
    locations : [
    {
      address : "",
      city : "",
      country : "",
      coordinate : {lat: "", lng: ""}
    }
    ],
    serviceCategory : []
  }
};

const InitialUserErrors = {
  emailError: false,
  roleError: false,
  emptyEmailError: false,
  nameError: false,
  phoneError: false,
  streetError: false,
  apartmentError: false,
  languageSpeakError: false,
  profileImageError: false,
  locationError: false,
  publicNameError: false,
  pincodeError: false,
  countryError: false,
  stateError: false,
  cityError: false,
  accountStreetError: false,
  accountCityError: false,
  accountPostcodeError: false,
  accountCountryError: false,
  interestCategoriesError : false,
  stuggleCategoriesError : false,
  taxNumberError: false
}

const Monthly_Plan_Array = [
  {
    subscriptionId : "1",
    subscriptionDesc : "The Perfect Plan for Newcomers and Beginners",
    subscriptionImage : "",
    subscriptionName : "Founding Catalyst",
    subscriptionPaymentPlan : "recurring",
    subscriptionPaymentDuration : "Monthly",
    subscriptionPaymentDurationInDays: 30,
    subscriptionPrice : "11,99",
    subscriptionTotalPrice : 11.99,
    discountVal: "+60% Lifetime discount",
    subscriptionPriceCurrency: "EUR",
    subscriptionTrialDuration: 0,
    lastPurchaseId: "",
    isBestValue : false,
    planExpireDate: 0,
    subscriptionStatus: "auth_pending",
    statusDescription: "",
    ServiceFeeDetails : [
      {
        serviceName: "CLASS",
        serviceFee : 2.5,
        serviceFeeType: "%"
      },
      {
        serviceName: "PRIVATE",
        serviceFee : 2.5,
        serviceFeeType: "%"
      },
      {
        serviceName: "WORKSHOP",
        serviceFee : 2.5,
        serviceFeeType: "%"
      }
    ],
    maxServicesAllowed: "",
    restrictedServices: ["EVENT"],
    subscription_benefit_list : [
      "All the perks with the lowest commission fees",
      "List your profile",
      "Unlimited service listing",
      "Chance to promote your events",
      "Quarterly market insights report for your Category",
      "Premium Profile Placement",
      "Dedicated Social Media and Comms."
    ]
  },
  {
    subscriptionId : "2",
    subscriptionDesc : "The Perfect Plan for Newcomers and Beginners",
    subscriptionImage : "",
    subscriptionName : "Founding Catalyst",
    subscriptionPaymentPlan : "recurring",
    subscriptionPaymentDuration : "Annually",
    subscriptionPaymentDurationInDays: 365,
    discountVal: "+60% Lifetime discount",
    subscriptionPrice : "107,91",
    subscriptionTotalPrice : 107.91,
    subscriptionPriceCurrency: "EUR",
    subscriptionTrialDuration : 0,
    lastPurchaseId: "",
    isBestValue : false,
    planExpireDate: 0,
    subscriptionStatus: "auth_pending",
    statusDescription: "",
    ServiceFeeDetails : [
      {
        serviceName: "CLASS",
        serviceFee : 2.5,
        serviceFeeType: "%"
      },
      {
        serviceName: "PRIVATE",
        serviceFee : 2.5,
        serviceFeeType: "%"
      },
      {
        serviceName: "EVENT",
        serviceFee : 5,
        serviceFeeType: "%"
      },
      {
        serviceName: "WORKSHOP",
        serviceFee : 5,
        serviceFeeType: "%"
      }
    ],
    maxServicesAllowed: "",
    restrictedServices: [],
    subscription_benefit_list : [
      "All the perks with the lowest commission fees",
      "List your profile",
      "Unlimited service listing",
      "Chance to promote your events",
      "Quarterly market insights report for your Category",
      "Premium Profile Placement",
      "Dedicated Social Media and Comms."
    ]
  }
]

const InitialPaymentDetails = {
  street1: "",
  pincode: "",
  city: "",
  country: ""
}

const InitialBookingDetails = {
  bookingId: "",
  scheduleId: "",
  userId: "",
  bookingDate: null,
  paymentStatus : "",
  paymentDetails: {},
  bookingAdditionalDetails: {},
  trail: [{}]
}

const InitialServiceDetails = {
  serviceCategoryDetails : {
    serviceCategoryId : "",
    serviceCategoryName: ""
  },
  userDetails : {
    userId : ""
  },
  serviceName : "",
  serviceDescription : "",
  serviceLocation : {
    city: "",
    address: "",
    country: "",
    coordinate: {
      lat: "",
      lng: ""
    },
    venueName: ""
  },
  serviceStatus : "",
  serviceType : "",
  serviceImg : [],
  serviceContactPersonDetails : {
    contactPersonName: "",
    contactPersonPhone: "",
    contactPersonEmail: ""
  },
  serviceStartDate : "",
  serviceEndDate : "",
  serviceTimezone : "",
  serviceScheduleDetails : {
    serviceMode: "physical",
    serviceScheduleType: "single",
    recurringOccurrence: "weekly",
    serviceScheduleTypeConfig: [],
    maxParticipant: "",
    serviceMeetingLink: "",
    guestDetails: [
      {
        guestName: "",
        guestPic: "",
        guestBio: ""
      }
    ]
  },
  servicePriceOptions : [],
  serviceAdditionalDetails : {}
}

const InitialScheduleDetails = {
  repeat: "weekly",
  recurring: [],
  contactPersonName : "",
  serviceScheduleType: "single",
  serviceStartDate : "",
  serviceEndDate : "",
  timezone: "",
  serviceMeetingLink : "",
  guestDetails : [
    {
      guestName : "",
      guestPic : "",
      guestBio : ""
    }
  ],
  scheduleInfo : []
}

const InitialServiceErrors = {
  course_name_error : false,
  course_description_error: false,
  course_category_error: false,
  course_price_error: false,
  course_duration_error: false,
  course_participant_error: false,
  course_location_error: false,
  price_info_error: false,
  course_start_date_error: false,
  course_end_date_error: false,
  course_date_range_error: false,
  course_start_time_error: false,
  course_end_time_error: false,
  course_time_range_error: false,
  course_timezone_error: false,
  course_schedule_error : false
}

const InitialTicketDetails = {
  id : "",
  ticketName : "",
  ticketDescription : "",
  ticketQuantityType : "limited",
  ticketMaxParticipant : "",
  couponInfo : [],
  policyInfo : [],
  packageType: "Fixed",
  ticketList : [
    {
      packageName: "",
      packagePrice: ""
    }
  ]
};

const InitialPackageDetails = {
  packageName: "",
  packageDescription : "",
  noOfSessions: "",
  packagePrice: "",
  priceCurrency: "EUR",
  packageValidityDuration: "",
  createdBy: "",
  status: "active"
}

const InitialPackageErrors = {
  package_name_error: false,
  no_of_sessions_error: false,
  package_price_error: false,
  package_validity_duration_error: false
}

const InitialPlanDetails = {
  planName: "",
  planDescription : "",
  planSessionType: "limited",
  planSessionPerMonth: 0,
  planPurchaseMethod: "recurring",
  planPrice: "",
  priceCurrency: "EUR",
  planPaymentPeriod: "",
  planValidityDuration: "",
  planFreeTrialEnabled: true,
  planTrialDuration: "",
  createdBy: "",
  status: "active"
}
 
const InitialPlanErrors = {
  plan_name_error: false,
  plan_session_per_month_error: false,
  plan_price_error: false,
  plan_payment_period_error: false,
  plan_validity_duration_error: false,
  plan_free_trial_enabled_error: false,
  plan_trial_duration_error: false
}

const InitialCouponDetails = {
  couponType : "EUR",
  couponName : "",
  couponCode : "",
  couponDiscount : "",
  discountCurrency : "EUR",
  couponStartDate : "",
  couponEndDate : "",
  couponMaxUsageCount : "",
  createdBy : ""
}

const InitialCouponErrors = {
  coupon_name_error : false,
  coupon_code_error : false,
  coupon_discount_error : false,
  coupon_start_date_error : false,
  coupon_end_date_error : false,
  coupon_date_range_error : false,
  coupon_usage_count_error : false
}

const InitialPolicyDetails = {
  policyName : "",
  rescheduleType : "allowed",
  rescheduleList : [
    {
      reschedulePermitHr: "10",
      rescheduleMaxCount: "2"
    }
  ],
  cancellationType : "allowed",
  cancellationList : [
    {
      cancelPermitHr: "10",
      cancelRefundMoney: "2"
    }
  ]
}

const InitialPolicyErrors = {
  policy_name_error : false,
  reschedule_list_error : false,
  cancellation_list_error : false
}

const Price_List_Array = [
  {
    "label": "Recurring payments",
    "value": "recurring"
  },
  {
    "label": "One time",
    "value": "onetime"
  },
  {
    "label": "Free",
    "value": "free"
  }
]

const initialPackageErrors = {
  ticketName_error : false,
  maxParticipant_error: false,
  package_price_error: false,
  variant_package_errors : []
};

const InitialSubscriptionDetails = {
  subscription_id : "",
  subscription_desc : "",
  subscription_image : "",
  subscription_name : "",
  subscription_payment_plan : "recurring",
  subscription_payment_period : "",
  subscription_price : "",
  isSubscriptionFreeTrailEnabled : true,
  subscription_trial_duration : "",
  subscription_class_service_fee_in_percentage : "",
  subscription_event_service_fee_in_percentage : "",
  is_best_value : false,
  subscription_benefit_list : [],
  include_benefits_of : {
    subscription_id: "",
    subscription_name: ""
  }
}

const InitialSubscriptionError = {
  subscription_name_error: false,
  subscription_payment_period_error : false,
  subscription_price_error: false,
  subscription_trial_duration_error: false,
  subscription_class_service_fee_in_percentage_error: false,
  subscription_event_service_fee_in_percentage_error: false,
  subscription_benefit_error: false
}

const InitialCategoryDetails = {
  category_id : "",
  category_name: "",
  category_picture: ""
}

const InitialCategoryErrors = {
  category_name_error : ""
}

const InitialGuestDetails = {
  guestName : "",
  guestPic : "",
  guestBio : ""
}

const InitialServiceBookingDetails = {
  firstname: "",
  lastname : "",
  password : "",
  email: "",
  confirmemail: "",
  phone: "",
  savecard: false
};

const InitialServicePaymentDetailsErrors = {
  cardNumberError : false,
  expireDateError: false,
  cvvError: false,
  cardHolderNameError : false
}

const TicketActionMenuItems = [
  {
    id: "edit",
    label: "Edit"
  },
  {
    id: "duplicate",
    label: "Duplicate"
  },
  {
    id: "delete",
    label: "Delete"
  }
]

const GeneralNavLinks = [
  {
    title: "Catalysts",
    path: "/catalysts"
  },
  {
    title: "Events & Classes",
    path: "/events-classes",
    type:'Select',
    options:Service_Tab_List
  },
  {
    title: "About",
    path: "/about"
  },
  {
    title: "Contact",
    path: "/contact"
  }
];

const Logged_Nav_Links = [
  {
    title : "Dashboard",
    path: "/dashboard"
  },
  {
    title: "Classes",
    path: "/classes"
  },
  {
    title: "Events",
    path: "/events"
  },
  {
    title: "Schedule",
    path: "/schedule"
  }
]

const InitialPaymentBillingAddress = {
  "street1": "",
  "street2": "",
  "city": "",
  "postcode": "",
  "country": "",
  "terms": false
}

const initialFilters = {
  isDayView : "false", 
  isMobileView : false,
  selectedCatalyst : [],
  selectedCategory : [],
  selectedLocation : [],
  startDate: new Date(),
  days : [],
  allLocationList: [],
  allServiceList: [],
  allCatalystList : []
}

const PackageDetails = {
  Package: "",
  Price: ""
}

const SubscribeDetails = {
  Subscribe: "",
  Price: ""
}


const InitialContactDetails = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  msg: ""
}

const headerOption = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json'
  }
  /*mode:'no-cors',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': '*'
  }*/
}

export {
  Catalyst_OnBoard_Header_Array,
  Class_Header_Array,
  Event_Header_Array,
  User_OnBoard_Header_Array,
  Private_Header_Array,
  Monthly_Plan_Array,
  /*Annual_Plan_Array,*/
  Mode_Array,
  Month_Array,
  Payment_Duration_Array,
  Category_List,
  Catalyst_Tab_List,
  Price_Methods_List,
  Service_Tab_List,
  Ticket_Tab_List,
  Service_Status_Array,

  Catalyst_Details_Service_Tab_List,
  InitialContactDetails,
  InitialServiceDetails,
  InitialServiceErrors,

  InitialScheduleDetails,

  InitialTicketDetails,
  InitialGuestDetails,

  InitialPolicyDetails,
  InitialPolicyErrors,

  InitialPackageDetails,
  InitialPackageErrors,

  InitialPlanDetails,
  InitialPlanErrors,

  InitialCouponDetails,
  InitialCouponErrors,

  InitialCategoryDetails,
  InitialCategoryErrors,

  InitialUserDetails,
  InitialUserErrors,

  InitialSubscriptionDetails,
  InitialSubscriptionError,

  InitialPaymentDetails,
  initialFilters,
  InitialBookingDetails,
  InitialPaymentBillingAddress,

  InitialServiceBookingDetails,
  InitialServicePaymentDetailsErrors,

  initialPackageErrors,
  headerOption,
  MyOrder_Period_Array,
  UserTypeArray,
  Policy_Array,
  Price_List_Array,
  Repeat_Options_Array,
  Location_Options_Array,
  Service_Schedule_Type_Array,
  TicketActionMenuItems,
  ticketQuantityType_array,
  Ticket_Participant_Array,
  Ticket_Price_Method,
  WeekList,
  Max_Schedule_List,
  GeneralNavLinks,
  Logged_Nav_Links,
  PackageDetails,
  SubscribeDetails,
  SERVICE_TABS_LABEL
};