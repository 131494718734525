import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {getLocationLink, getTimeFromEpoc, isScheduleExpired} from '../../utils/util';
import {getTimeZoneKey} from '../../utils/DefaultStates';
import location_icon from '../../assets/Location pin.png';
import participants_icon from '../../assets/participants_icon.svg';

const DayCard = (props) => {
	
	const {
		item = {}, 
		onHandleItemClick, 
		onHandleCTAClick, 
		activeTab = "-1",
		CTALabel = "", 
		isCTAShow = true,
		service_Type = "",
		isCTADisabled = false,
		isShowMeetingLink = false,
		isShowParticipantCount = false
	} = props;

	const {
		scheduleMode = "",
		availableSeatCount = 0,
		serviceType = "",
		scheduleStartTime = "",
		scheduleEndTime = "",
		scheduleBookedCount = -1,
		scheduleMaxParticipants = 0
	} = item || {};
	
	const {
        scheduleId = "",
        serviceName = "",
        serviceId = "",
        serviceDescription = "",
        day = "",
        serviceCategoryDetails = {},
        startTime = "",
        endTime = "",
        service_date = "",
        serviceScheduleDetails = {},
        serviceLocation = {},
        duration = "",
        booking_details = [],
        userDetails = {},
        serviceTimezone = ""
    } = item?.serviceDetails || {};

    const {
    	serviceMeetingLink = ""
    } = serviceScheduleDetails || {};

	const [isExpanded, setExpanded] = useState(false);
	const [isExpired, setExpired] = useState(false);

	useEffect(() => {
    	const isExp = isScheduleExpired(scheduleEndTime);
      	setExpired(isExp);
	}, []);

	const getCTALabel = (isExpired = false, avlCount = 0, label = "") => {
	    if(isExpired) return "Expired";
	    else if(avlCount === 0) return "Sold Out";

	    return label;
	}

	const isDisabledBtn = () => {
		if(isExpired || isCTADisabled || item?.isDisabled === "true" || availableSeatCount === 0) {
			return true;
		}
		return false;
	}

	const MaxTextValue = scheduleMaxParticipants == "-1" ? "Unlimited" : scheduleMaxParticipants;
	const CTALabelValue = getCTALabel(isExpired, availableSeatCount, CTALabel);
	const isDisabled = isDisabledBtn();

	return (
		<>
			<div className="desktop_view my-3">
				<div className="rounded-4 dayview_container py-3 px-5">
					<div className="d-flex justify-content-between">
						<div className="d-flex flex-column justify-content-between">
							{serviceType !== "PRIVATE" && <div className="caption-2 color-primary-100">{startTime} - {endTime} {getTimeZoneKey(serviceTimezone)}</div>}
							{serviceType === "PRIVATE" && <div className="caption-2 color-primary-100">{getTimeFromEpoc(scheduleStartTime, serviceTimezone)} - {getTimeFromEpoc(scheduleEndTime, serviceTimezone)} {getTimeZoneKey(serviceTimezone)}</div>}
							<div>
								<div className="caption-2 color-primary-50">{userDetails?.userName?.toUpperCase() || "-"}</div>
								
							</div>
							<div>
								<button 
									className="ax-button caption-2 color-primary-50" 
									onClick={() => setExpanded(!isExpanded)}
									disabled = {isDisabled}
								> 
									{isExpanded ? "-" : "+"} info
								</button>
							</div>
						</div>

						{isShowParticipantCount && <div className="caption-3 color-primary-50 py-1">
							<img src={participants_icon} style={{width: "20px"}} />
							{`${scheduleBookedCount} / ${MaxTextValue}`} Participants
							</div>
						}

						<div className="d-flex flex-column justify-content-between">
							<h5 className="caption-2 color-primary-50">{serviceName}</h5>
							
							<div className="d-flex align-items-end">
								{scheduleMode === "physical" && <Link to={getLocationLink(serviceLocation)} target="_blank">
									<div className="py-2 caption-2 color-primary-50 d-flex">
										<img src = {location_icon} style = {{width: '15px', height: '15px', marginTop: '7px'}} alt = "" />
										<h6 className="p-2 caption-2 color-primary-50">{serviceLocation?.city || scheduleMode}</h6>
									</div>
								</Link>}
								{scheduleMode === "online" && <div className="py-2 caption-2 color-primary-50 d-flex">
									<h6 className="p-2 caption-2 color-primary-50">Online</h6>
								</div>}
							</div>
						</div>

						<div className="d-flex justify-content-center">
							{isCTAShow && <button 
								className="capitalize secondary_btn" 
								onClick={() => onHandleCTAClick(item)} 
								disabled = {isDisabled}
							>
								{CTALabelValue || CTALabel}
							</button>
							}
						</div>
					</div>

					{(isExpanded && ((scheduleMode !== "online" && isShowMeetingLink) || !isShowMeetingLink)) && <div className="py-2 color-primary-50 subtitle-3">{item?.serviceDetails?.serviceDescription || "-"}</div>}
					{isExpanded && scheduleMode === "online" && isShowMeetingLink && <a className="py-2 color-primary-50 subtitle-3" href={serviceMeetingLink} target="_blank">Meeting Link</a>}
				</div>
			</div>
			
			<div className="dayview_container rounded-4 mobile_view my-3">
				<div className="padding">
					<div className="d-flex justify-content-between align-items-center">
						<div className="subtitle-2 color-primary-50">{userDetails?.userName?.toUpperCase() || "-"}</div>
						<div className="subtitle-3 color-primary-50">
							<button 
								className="ax-button" 
								onClick={() => setExpanded(!isExpanded)} 
								disabled = {isDisabled}
							>
								{isExpanded ? "-" : "+"} info
							</button>
						</div>
					</div>

					<div>
						<h4 className="heading-4 color-primary-50">{serviceName}</h4>
					</div>

					<div className="py-2 d-flex justify-content-between align-items-center">
						<div className="subtitle-3 color-primary-50">{startTime} - {endTime}</div>
						<div className="caption-2 color-primary-50">{userDetails?.userName?.toUpperCase() || "-"}</div>
								
						<div className="d-flex align-items-end">
							{scheduleMode === "physical" && <Link to={getLocationLink(serviceLocation)} target="_blank">
								<div className="py-2 caption-2 color-primary-50 d-flex">
									<img src = {location_icon} style = {{width: '15px', height: '15px', marginTop: '7px'}} alt = "" />
									<h6 className="p-2 caption-2 color-primary-50">{serviceLocation?.city || scheduleMode}</h6>
								</div>
							</Link>}
							{scheduleMode === "online" && <div className="py-2 caption-2 color-primary-50 d-flex">
								<h6 className="p-2 caption-2 color-primary-50">Online</h6>
							</div>}
						</div>
					</div>
					
				</div>
				<div className="d-flex justify-content-center pt-3">
					<button 
						className="capitalize book_btn py-1" 
						onClick={() => onHandleCTAClick(item)} 
						disabled = {isDisabled|| (isShowParticipantCount && scheduleBookedCount === 0)}
					>
						{CTALabelValue || CTALabel}
					</button>
				</div>
			</div>
		</>
	)
}

export default DayCard;