import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import HelmetDetails from "../../components/HelmetDetails";
import TabView from '../../components/tabView/TabView';

import {Service_Tab_List} from '../../utils/DefaultInitialValues';
import PurchaseDialog from './servicePurchaseDialog/PurchaseDialog';
import ClassListView from '../../components/classListView/ClassListView';
import FilterView from '../../components/filters/FilterView';
import HelmetText from '../../components/helmet-text/HelmetText';
import Loading from '../../components/loading/Loading';

import AuthDialog from '../../components/authenticate/AuthDialog';

import ScheduleDialog from './scheduleDialog/ScheduleDialog';
import CustomizedSnackbars from  '../../components/CustomizedSnackbars';

import {getSchedules} from '../../actions/schedules.actions';
import {saveAdmin} from '../../actions/admin.actions';
import {generateEventsDropdownList, getTypeFromActiveTab, isEmptyJson} from '../../utils/util';
import {mindedClubUserId} from '../../../src/actions/Constants';
import './events.css';
import { useSearchParams } from 'react-router-dom';

const activeTabsValue = ["CLASS", "PRIVATE", "EVENT"];

const Events = (props) => {
	const dispatch = useDispatch();
	const getSchedulesAction = getSchedules;
	const saveAdminAction = saveAdmin;
	const [allSchedules, setAllSchedules] = useState([]);
	const [filterSchedules, setFilterSchedules] = useState([]);
	
	const [allDropdownList, setAllDropdownList] = useState({});
	const [service_Type, setServiceType] = useState("");

	const [startEpoc, setStartEpoc] = useState("");
	const [endEpoc, setEndEpoc] = useState("");

	const [isShowDialog, setShowDialog] = useState(false);
	const [isShowLoginDialog, setShowLoginDialog] = useState(false);
	const [isShowScheduleDialog, setShowScheduleDialog] = useState(false);
	const [purchaseId, setPurchaseId] = useState("");
	const [scheduleDetails, setScheduledetails] = useState({});
	const [searchParam,setSearchParam] = useSearchParams()

	const [isRoleError, setRoleError] = useState(false);

	const [selectedFilters, setSelectedFilters] = useState({
		selectedCatalyst : [],
		selectedLocation: [],
		selectedCategory : []
	});

	const {
    	details: loggedUser
  	} = useSelector(({ loggedAdmin: { details} }) => ({ details }));

    const {
       details,
       loading,
       error
    } = useSelector(({ schedules: { details, loading, error} }) => ({ details, loading, error }));

    const onCheckboxSelect = (name, val) => {
    	setSelectedFilters({...selectedFilters, [name] : val});
    }

    const onLoginSuccess = (isSignupView, userinfo) => {
    	if(userinfo && userinfo?.role === "customer") {
    		dispatch(saveAdminAction(userinfo));
			setShowDialog(true);
			setRoleError(false);
		} else {
			setRoleError(true);
			setShowDialog(false);
			setScheduledetails({});
		}
	}

	const onHandleScheduleNow = (purchaseInfo = {}) => {
		setPurchaseId(purchaseInfo?.purchaseId);
		//setPurchaseId("123");
		setShowScheduleDialog(true);
	}

    const handleDateChange = (stDate, edDate) => {
    	const type =searchParam.get('search') || 'CLASS'

    	if(stDate && edDate) {
	    	const startDateEpoc = new Date(stDate).getTime();
	    	const endDateEpoc   = new Date(edDate).getTime();

	    	setStartEpoc(startDateEpoc);
	    	setEndEpoc(startDateEpoc);
	    	
	    	dispatch(getSchedulesAction("", "", type, "", startDateEpoc, endDateEpoc, "", ""));
	    	
    	}
    }

    const getFilterData = () => {
    	const filterLocationList = allSchedules;
    	return filterLocationList;
    }

    const applyFilter = () => {
  
    	const { selectedCatalyst = [], selectedLocation = [], selectedCategory = [] } = selectedFilters || {};

    	let filterArray = allSchedules;

		// Remove 'Minded Club' schedules from general calendar
		filterArray = allSchedules.filter((item, index) =>item?.userId !== mindedClubUserId);

    	if(selectedCatalyst.length) {
    		filterArray = allSchedules.filter((item, index) => selectedCatalyst.includes(item?.serviceDetails?.userDetails?.userId));
    	}

    	if(selectedCategory.length) {
    		filterArray = filterArray.filter((item, index) => selectedCategory.includes(item?.serviceDetails?.serviceCategoryDetails?.serviceCategoryId));
    	}

    	if(selectedLocation.length) {
    		filterArray = filterArray.filter((item, index) => selectedLocation.includes(item?.serviceDetails?.serviceLocation?.city));
    	}

    	setFilterSchedules(filterArray);
    }

    useEffect(() => {
    	applyFilter();
    }, [selectedFilters]);

	const currentIndex  = activeTabsValue.indexOf(searchParam.get('search'))
	
    useEffect(() => {
		const type = searchParam.get('search')
		setServiceType(type);
		if(startEpoc !== "" && endEpoc !== "" && type) {
			dispatch(getSchedulesAction("", "", type, "", startEpoc, endEpoc, "", "")); 
		}
	}, [searchParam]);

	useEffect(() => {
		if(currentIndex !== -1)
		setSearchParam({ search: activeTabsValue[currentIndex] });
	  }, [currentIndex]);

    useEffect(() => {
    	if(Array.isArray(details)) {
    		setAllSchedules(details);
    	}
    }, [details]);

    useEffect(() => {
    	if(Array.isArray(allSchedules)) {
    		const obj = generateEventsDropdownList(allSchedules);
    		setAllDropdownList(obj);
    		applyFilter();
    	}
    }, [allSchedules]);

	useEffect(() => {
    	window.scrollTo({top: 0, behavior: "smooth"});    	
    }, []);

    useEffect(() => {
    	if(!isEmptyJson(scheduleDetails)) {
    		onHandleBookNow();
    	}
    }, [scheduleDetails]);

	const onHandleCTAClick = (schedule = {}) => {
		const newSchedule = schedule;
		setScheduledetails(newSchedule);
	}

	const onHandleBookNow = () => {
    	if(isUsedLoggedIn) {
    		setShowDialog(true);
    	} else {
    		setShowLoginDialog(true);
    	}
    }

	const TabPanel = (service_Type) => {
		return (
			<>
				<div className="container-fluid my-3">
					<FilterView 
						onHandleDateChange = {handleDateChange} 
						allDropdownList = {allDropdownList}
						onCheckboxSelect = {onCheckboxSelect} 
					/>
				</div>
			
				<ClassListView 
					onHandleCTAClick = {(schedule) => onHandleCTAClick(schedule)}
					serviceList = {filterSchedules}
					service_Type = {service_Type}
					isCTADisabled = {loggedUser && loggedUser?.role && (loggedUser?.role === "admin" || loggedUser?.role === "catalyst")}
				/>
			</>
		);
	}

	const onCloseAuthDialog = (state = "") => {
		if(state !== "success") {
			setScheduledetails({});
		}
		setShowLoginDialog(false);
	}

	const isUsedLoggedIn = loggedUser && loggedUser?.userId;
	const isLoading = loading;

	return (
		<>
			<HelmetDetails title="Events | Minded." />
			<HelmetText />
			<div className="main-content-padding">
				<div className="w-100">
					<div className="mobile_view">
						<h1 className="heading-1 color-primary-50 text-center px-3">Events and Classes</h1>
					</div>
					
					<TabView
						children={(service_Type) => TabPanel(service_Type)}
						tabs={Service_Tab_List}
						onTabClick={(val) => setSearchParam({search:activeTabsValue[val]})}
						service_Type={service_Type}
						currentValue={activeTabsValue.indexOf(searchParam.get('search'))}
          			/>
					
				</div>
			</div>
			{isLoading && <Loading />}

			{isShowLoginDialog && <AuthDialog 
				isOpen = {isShowLoginDialog} 
				onHandleClose = {(state) => onCloseAuthDialog(state)}
				onLoginSuccess = {(isSignupView, userinfo) => onLoginSuccess(isSignupView, userinfo)}
			/>}

			{isShowDialog && !isRoleError && <PurchaseDialog 
				isOpen = {isShowDialog}
				scheduleDetails = {scheduleDetails} 
				onHandleClose = {() => {setShowDialog(false);setScheduledetails({})}} 
				onHandleScheduleNow = {(bookinginfo) => onHandleScheduleNow(bookinginfo)}
			/>}

			{isShowScheduleDialog && <ScheduleDialog 
				isOpen = {isShowScheduleDialog} 
				onHandleClose={() => setShowScheduleDialog(false)}
				purchaseId = {purchaseId}
			/>}

			{(error || isRoleError) && <CustomizedSnackbars isShow={error || isRoleError} text= {error ? "Something went wrong. Please try after sometime." : "Please login as minded to do a purchase"} />}
		</>
	)
}

export default Events;