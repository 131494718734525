import React, {useState, useEffect} from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb } from "rsuite";
import TabView from '../../../../components/tabView/TabView';
import HelmetText from '../../../../components/helmet-text/HelmetText';
import right_arrow from '../../../../assets/thin arrow - right.png';

import CategoryDetails from './CategoryDetails';
import SubscriptionDetails from './SubscriptionDetails';
import PlanDetails from './PlanDetails';
import CouponDetails from './CouponDetails';
import PackageDetails from './PackageDetails';
import PromoCodes from './PromoCodes';

const AdminSettings = () => {
	const location = useLocation();
	const [crumb, setCrumb] = useState([]);
	const [activeTab, setActiveTab] = useState(0);

	const role = "admin";

	useEffect(() => {
        let locationVal = location?.pathname;
        const crumbs = location.pathname.split("/");
        createCrumbDetails(crumbs);
    }, [location]);

    const tabList = [
		
		{
			"label": "Subscriptions",
			"value" : "subscriptions"
		},
		{
			"label": "Categories",
			"value" : "categories"
		},
		{
			"label":"Promocodes",
			"value":"Promocodes"
		}
	];

	const TabPanel = () => {
		return (
			<>
				{activeTab === 0 && <SubscriptionDetails pageName = "Admin" isDisabled="true" />}
				{activeTab === 1 && <CategoryDetails />}
				{activeTab === 2 && <PromoCodes />}
			</>
		);
	}

	const createCrumbDetails = (crumbs) => {
		const crumbArray = [];
		
		crumbs.map((crmb, index) => {
			const obj = {};

			switch(crmb) {
				case "":
					obj.path = "/";
					obj.label = "Home";
					crumbArray.push(obj);
					break;
				case "dashboard":
					obj.path = "/dashboard";
					obj.label = "My Dashboard";
					crumbArray.push(obj);
					break;
				case "settings":
					obj.path = "/dashboard/settings";
					obj.label = "Settings";
					crumbArray.push(obj);
					break;
				
			}

			setCrumb(crumbArray);
		});
	}

	return (
		<div className="main-no-content-padding">
			<Breadcrumb> 
                {crumb.map((item, index) => {
                	return (index !== crumb.length - 1) ? (
	                	<>
		                	<Link to={item.path}>
		                		<span className={`body-R color-secondary-100 px-2`}>{item.label}</span>
		                	</Link>
		                	<img src={right_arrow} style={{width: '12px', height: '12px'}} />
	                	</>
	                	) : (<span className={`body-R color-neutral-50 px-2`}>{item.label}</span>)
                	}
                )}
	        </Breadcrumb>

	        <h1 className="heading-1 color-primary-50">Settings</h1>
			<div className="container">
 	 			<TabView children = {TabPanel} tabs={tabList} onTabClick = {(val) => setActiveTab(val)}  />
			</div>
		</div>
	)
}

export default AdminSettings;